import React, { useState, useEffect, useRef } from "react";
import css from "../css/gamehis.module.css";
import { Button } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Invoice from "./Invoice";
import { AiOutlineDownload } from "react-icons/ai";
import "./invoice.css";
import socket from "../Components/socket";
import { BsFillEyeFill } from "react-icons/bs";
import { apiRequest } from "../store/allApi";

const PenaltyHistory = () => {

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++ 
  let printRef = useRef(null);

  const [show, setShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setInvoiceData(e);
  };

  const [user, setUser] = useState("");

  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`me`, "GET" , null , headers)
      setUser(res.data);
      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    } catch (e) {
      
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);

  };

  // ALL APIS HERE

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setIsLoading(true);

    try {
       const res = await apiRequest(`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, "GET", null, headers)
       setGame(res.data.ress);
       {
         // console.log(res.data.ress);
       }
       setNumberOfPages(res.data.totalPages);
       setIsLoading(false);
    } catch (e) { 
    }
  };



  useEffect(() => {
    console.log(cardData)
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  const [Withdrawal, setWithdrawal] = useState();
  const [showBottom, setShowBottom] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = (data) => {
    setShowBottom(true);
    setWithdrawal(data);
  };

  // const dateFormat = (e) => {
  //   const date = new Date(e);
  //   const newDate = date.toLocaleString("default", {
  //     month: "short",
  //     day: "numeric",
  //     hour: "numeric",
  //     hour12: true,
  //     minute: "numeric",
  //   });
  //   return newDate;
  // };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${newDate}, ${time}`;
  };

  return (
    <>
      <div>
          {/* game-cards */}

          {isLoading ? (
            <div className="text-center">
              {cardData && cardData.length === 0 ? (
                <div className="mt-2">
                  <h3 className="font-weight-bold">No transaction History</h3>
                  <p className="text-muted">
                    You have not made any transaction yet.
                  </p>
                </div>
              ) : (
                <div
                  className="loaderReturn text-center"
                  style={{ zIndex: "99" }}
                >
                  <img
                    src={"/images/LandingPage_img/loader1.gif"}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
            </div>
          ) : (
            cardData &&
            cardData.length === 0 && (
              <div className="mt-2 text-center">
                <h3 className="font-weight-bold">No transaction History</h3>
                <p className="text-muted">
                  You have not made any transaction yet.
                </p>
              </div>
            )
          )}
      
        {/* new code history  */}

        <div className="transaction_main_section_modal">
          {cardData &&
            cardData
              .filter((card) => card?.Req_type?.toLowerCase().trim() === "penalty")
              .map((card) => {
                var id = card._id.toString(),
                  ctr = 0;
                var timestamp = id.slice(ctr, (ctr += 8));
                var machineID = id.slice(ctr, (ctr += 6));
                var processID = id.slice(ctr, (ctr += 4));
                var counter = id.slice(ctr, (ctr += 6));
                var counterid = parseInt(timestamp, 16);

                var titleMsg = "Penalty"; // Always show "Penalty"
                var signIcon = <div className="text-danger">(-)</div>;
                return (
                  <>
                    <div className="other_pages_card_main d-block text-center">
                  
                      <div className="d-flex align-items-center justify-content-between">
                      <div className="card_date text-center border-right-1 pr-3">
                            {/* <p>{dateFormat(card.createdAt).split(",")[0]}</p>
                            <p>{dateFormat(card.createdAt).split(",")[1]}</p> */}
                             {dateFormat(card.createdAt).split(",")[0]} <br/> {dateFormat(card.createdAt).split(",")[1]}
                          </div>
                        {/* <div>
                          <h3>{titleMsg}</h3>

                        </div> */}
                        <div className="text-start">

                          {/* <h3>
                          Order ID:{" "}
                          <span className="dark_light_color"> {counterid}</span>
                        </h3> */}

                          <h3>
                            {/* Status: */}
                            <span className="  btn-lg  px-3 py-2 text-white btn-success font-w700">
                              {card.status === "none" ||
                                (card.status === "Pending" &&
                                  card.Req_type === "withdraw")
                                ? "Processing"
                                : card.status === "Pending" &&
                                  card.Req_type === "deposit"
                                  ? "Cancelled"
                                  : card.status}
                            </span>
                          </h3>

                        </div>

                        <div className="transaction_right_side_main">
                          {card?.Req_type == "withdraw" && (
                            <button
                              onClick={() => {
                                handleShowBottom(card);
                              }}
                              className="eye_button"
                            >
                              <BsFillEyeFill className="icons" />
                            </button>
                          )}
<div className="card_rupee d-flex float-right font-w700">
                          {signIcon}
                          <picture className="">
                            {/* <img
                              alt="img"
                              height="21px"
                              width="21px"
                              src={
                                process.env.PUBLIC_URL +
                                "/nkcoin.png"
                              }
                              className="snip-img"
                            /> */}
                          </picture>
                          <span className="pl-1">{card.amount}</span>
                        </div>
                          <div>
                            {card.closing_balance && (
                              <div
                                className={`${css.games_section_headline} gamehis_games_section_headline__BVYUG`}
                                style={{ fontSize: "12px", marginTop: "8px", whiteSpace: "nowrap", color: "#757575", }}
                              >

                                 Cl Bal : ₹  {card.closing_balance}
                              </div>
                            )}
                          </div>
                          
                        </div>
                      </div>
                      <div className="mt-2 d-flex align-items-center default-position">
                        
                        <p className="ml-3"> <b>Reson-</b> </p>
                        <p className="">
                          {" "}
                          {card.txn_msg ? card.txn_msg : ""}
                        </p>
                      </div>

                    </div>

                  </>
                );
              })}
        </div>
        <div>

        </div>
      </div>


    </>
  );
};

export default PenaltyHistory;
