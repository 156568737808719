import CryptoJS from 'crypto-js';

const CALLBACK_HASH_SECRET_KEY = process.env.REACT_APP_SECRET_KEY; // Get key from .env

export function createNewHmac(data) {
  if (!CALLBACK_HASH_SECRET_KEY) {
    throw new Error("HMAC Secret Key is missing in environment variables.");
  }

  const hashResp = CryptoJS.SHA256(CALLBACK_HASH_SECRET_KEY);

  delete data.hmac;

  if ('actions' in data) {
    const actionss = data.actions
      .map((action) =>
        Object.keys(action)
          .sort()
          .map((key) => action[key])
          .join('')
      )
      .join('');

    data.actions = actionss;
  }

  const hmacbaseRes = Object.keys(data)
    .sort()
    .map((key) => data[key])
    .join('');

  const hmacResp = CryptoJS.HmacSHA256(hmacbaseRes, hashResp);
  return CryptoJS.enc.Hex.stringify(hmacResp);
}

export function verifyHmac(payload) {
  if (!CALLBACK_HASH_SECRET_KEY) {
    throw new Error("HMAC Secret Key is missing in environment variables.");
  }

  const base = { ...payload };
  delete base.hmac;

  if ('actions' in base) {
    let actions = '';
    for (const action of base.actions) {
      Object.keys(action)
        .sort()
        .forEach((key) => (actions += action[key]));
    }
    base.actions = actions;
  }

  const hash = CryptoJS.SHA256(CALLBACK_HASH_SECRET_KEY);
  const hmac = CryptoJS.HmacSHA256('', hash);

  let hmacBase = '';
  Object.keys(base)
    .sort()
    .forEach((key) => (hmacBase += base[key]));

  const hmacString = CryptoJS.HmacSHA256(hmacBase, hmac).toString(CryptoJS.enc.Hex);

  return hmacString === payload.hmac;
}
