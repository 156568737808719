import axios from "axios";
import React, { useRef, useState, useEffect, useContext } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import css from "../css/Addcase.module.css";
import Compressor from "compressorjs";
import css1 from "../css/Pan.module.css";
import Rightcontainer from "../Components/Rightcontainer";
import "../css/Loader.css";
import { FaArrowUp } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import "../css/viewGame1.css";
import "../css/layout.css";
import { TbCoinRupeeFilled } from "react-icons/tb";
import Swal from "sweetalert2";
import Header from "../Components/Header";
import socket from "../Components/socket";
import "../css/landing.css";
import Modal from "react-bootstrap/Modal";
import { BsArrowRight, BsQrCodeScan } from "react-icons/bs";
import DownloadLink from "react-download-link";
import Spinner from 'react-bootstrap/Spinner';
import { LanguageContext } from "../Components/LanguageContext";
import { useTheme } from "../../ThemeContext";
import QRCode from "qrcode.react";
import { apiRequest } from "../store/allApi";
import { Details } from "@mui/icons-material";


const Addcase = ({ walletUpdate }) => {
  // BASE URL

  // const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  // const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const nodeMode = process.env.NODE_ENV;
  // if (nodeMode === "development") {
  //   var baseUrl = beckendLocalApiUrl;
  // } else {
  //   baseUrl = beckendLiveApiUrl;
  // }

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // +++++++++++++++++++++++++++++

  const history = useHistory();
  let method = useRef();
  let checkInterval;
  const [userAllData, setUserAllData] = useState();

  const access_token = localStorage.getItem("token");

  const [isLoading1, setIsloading1] = useState(false);
  const [loading, setLoading] = useState(false)
  const [allBank, setAllBank] = useState([])

  const [global, setGlobal] = useState(100);
  const [next, setNext] = useState(1);
  const [process1, setProcess] = useState(false);
  const [isMobile, setMobile] = useState(false);

  const [isCashFreeActive, setCashFreeActive] = useState(true);
  const [isPhonePayActive, setIsPhonePayActive] = useState(false);
  const [upigateway, setUpigateway] = useState(false);
  const [upigateway1, setupigateway1] = useState(false);
  const [upigatewayGk, setUpigatewayGK] = useState(false);
  const [isAirPayActive, setIsAirPayActive] = useState(false);
  const [isKvmPayActive, setIsKvmPayActive] = useState(false);
  const [isMyPayActive, setIsMyPayActive] = useState(false);
  const [isPineLabActive, setPineLabActive] = useState(false);
  const [isRazorPayActive, setRazorPayActive] = useState(false);
  const [isDecentroActive, setDecentroActive] = useState(false);
  const [isHoadaPaypayInActive, setHoadaPaypayInActive] = useState(false);
  const [ManualPaymentdata, setManualPaymentdata] = useState([]);

  const [manualData, setManualData] = useState([]);
  const [PayOneImage, setPayOneImage] = useState("");
  const [PayTwoImage, setPayTwoImage] = useState("");
  const [PayThreeImage, setPayThreeImage] = useState("");
  const [PayFourImage, setPayFourImage] = useState("");
  const [PayFiveImage, setPayFiveImage] = useState("");

  // hooks for payment url
  const [PayNow1Url, setPaynow1Url] = useState("");
  const [PayNow2Url, setPaynow2Url] = useState("");
  const [PayNow3Url, setPaynow3Url] = useState("");
  const [PayNow4Url, setPaynow4Url] = useState("");
  const [PayNow5Url, setPaynow5Url] = useState("");

  const [show_won, setShow_won] = useState(false);
  const [show_won1, setShow_won1] = useState(false);
  const handleClose_won = () => setShow_won(false);
  const [scrnshot, setScrnshot] = useState(null);
  const [fecthStatus, setFecthStatus] = useState();
  const [scrnshot1, setScrnshot1] = useState("");
  const handleShow_won = () => setShow_won(true);
  const handleShow_won1 = () => setShow_won1(true);
  const handleClose_won1 = () => setShow_won1(false);

  const [qrCode, setQrCode] = useState();
  const [walletOption, setWalletOption] = useState("airtel");
  const [bankCode, setBankCode] = useState(3003);

  const [account_mail_id, setAccount_mail_id] = useState();
  const [account_name, setAccount_name] = useState();
  const [accountPhone, setAccountPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [minLimit, setMinLimit] = useState(null);
  const [maxLimit, setMaxLimit] = useState(null);
  const [amount, setamount] = useState("");
  const [utr, setutr] = useState("");
  const [acdata, setacdata] = useState('')

  // console.log(maxLimit)

  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);

  // const styles3 = {
  //   backgroundColor: theme === 'light' ? '#facc06' : '#27241b',
  //   color: theme === 'light' ? 'black' : '#fff',
  // };

  // Hoada Hooks



  const [hoadaPay, setHoadaPay] = useState(false);

  useEffect(() => {
    setMinLimit(addCaseSetting.depositlimitMin);
    setMaxLimit(addCaseSetting.depositlimitMax);

    if (!addCaseSetting.isDeposit) {
      <script>

        document.getElementById("addcase").style.display = "none";
        document.getElementById("depositClose").style.display = "block";
      </script>
    }
  }, []);

  const handleNext = () => {
    if (global < minLimit || global > maxLimit) {
      Swal.fire({
        title: `Minimum amount should be greater than ${minLimit} and maximum amount should be less than ${maxLimit}`,

        confirmButtonText: "OK",
        content: "custom-swal-content",
      });
    } else {
      setNext(2);
    }
  };

  //Function to load razorpay script for the display of razorpay payment SDK.
  function loadRazorpayScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }





  const [upiLink, setUpiLink] = useState("");



  const checkupideposit = async (order_id, order_token) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`depositupipay/response`, "POST", { order_id, order_token }, headers)
      const icon = res.data.status == "PAID" ? "success" : "danger";
      const title =
        res.data.status == "PAID"
          ? "Deposit submited successfully"
          : "Transaction Failed";
      history.push("/");
      setTimeout(() => {
        Swal.fire({
          title: title,
          icon: icon,
          confirmButtonText: "OK",
        });
      }, 1000);
    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("token");
        window.location.reload();
      }
    }
  };

  //use for cashfree gatway
  const deposit = async (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const Details = {
      amount: global,
      channel: channel,
      payment_method: method,
      provider: walletOption,
      bankCode: bankCode,
    };

    try {
      const res = await apiRequest(`user/depositeupi`, "POST", Details, headers)
      setProcess(false);

      if (res.data?.data?.status == false) {
        Swal.fire({
          title: res?.data?.data?.msg,
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        window.location.href = res.data.data.data.payment_url;

      }
      // if (res.data.data.payment_method == "app") {
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      // } else if (
      //   res.data.data.channel == "link" &&
      //   res.data.data.payment_method == "upi"
      // ) {
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      //   window.location.href = res.data.data.data.payload[upiMethod];
      // } else if (
      //   res.data.data.channel == "qrcode" &&
      //   res.data.data.payment_method == "upi"
      // ) {
      //   setQrCode(res.data.data.data.payload.qrcode);
      //   setProcess(false);
      //   setNext(3);
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      // }
    } catch (e) {
      alert(e);
    }
  };
  const depositUpi = async (channel, method, upiMethod) => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    const Details = {
      amount: global,
      channel: channel,
      payment_method: method,
      provider: walletOption,
      bankCode: bankCode,
    };

    try {
      const res = await apiRequest(`user/depositeupi1`, "POST", Details, headers)
      setProcess(false);

      if (res.data?.data?.status == false) {
        Swal.fire({
          title: res?.data?.data?.msg,
          icon: "error",
          confirmButtonText: "OK",
        });
      } else {
        window.location.href = res.data.data.data.payment_url;

      }
      // if (res.data.data.payment_method == "app") {
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      // } else if (
      //   res.data.data.channel == "link" &&
      //   res.data.data.payment_method == "upi"
      // ) {
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      //   window.location.href = res.data.data.data.payload[upiMethod];
      // } else if (
      //   res.data.data.channel == "qrcode" &&
      //   res.data.data.payment_method == "upi"
      // ) {
      //   setQrCode(res.data.data.data.payload.qrcode);
      //   setProcess(false);
      //   setNext(3);
      //   checkInterval = setInterval(
      //     (ID) => {
      //       checkout(ID);
      //     },
      //     10000,
      //     res.data.txnID
      //   );
      // }
    } catch (e) {
      alert(e);
    }
  };




  const meapi = async () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      setUserAllData(res.data);
      setAccount_mail_id(res.data.Email);
      setAccount_name(res.data.holder_name);
      setAccountPhone(res.data.Phone);
    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/login");
      }
    }
  }

  const websitesetting = async () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`website/setting`, "GET", null, headers)
      console.log(res, "site");
      setCashFreeActive(res.data.isCashFreeActive);
      setRazorPayActive(res.data.isRazorPayActive);
      setDecentroActive(res.data.isDecentroActive);
      setPineLabActive(res.data.isPineLabActive);
      setIsPhonePayActive(res.data.isPhonePayActive);
      setIsAirPayActive(res.data.isAirPayActive);
      setIsMyPayActive(res.data.isMyPayActive);
      setIsKvmPayActive(res.data.isKvmPayActive);
      setHoadaPaypayInActive(res.data.isHaodaPayPayInActive);
    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("token");
        window.location.reload();
        history.push("/login");
      }
    }

    if (
      /Android|webOS|iPhone|iPad|iPod|#fffBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      setMobile(true);
    }
  }

  useEffect(() => {
    meapi();
    // websitesetting();

  }, []);

  // QR CODE IMAGES DOWNLOAD ---------------------------------------------------

  const handleImageDownload = (item) => {
    const link = document.createElement("a");
    link.href = item;
    link.download = "QR-Code.png"; // You can specify the downloaded file name here
    link.click();
  };
  // clear Image
  const clearImage = (e) => {
    setScrnshot1(null);
    setScrnshot(null);
  };
  // MANUAL PAYMENTS METHODS API

  const handleChange = (e) => {
    setScrnshot1(URL.createObjectURL(e.target.files[0]));
    const image = e.target.files[0];
    if (image && image.size / 1000 > 300) {
      console.log(" compression");
      new Compressor(image, {
        quality: 0.6,
        success(compressedResult) {
          if (compressedResult) {
            setScrnshot(compressedResult);
          } else {
            setScrnshot(image);
          }
        },
      });
    } else {
      setScrnshot(e.target.files[0]);
    }
  };

  const ManualPayment = async () => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    try {
      const res = await apiRequest(`gatewaysettings/data`, "GET", null, headers)
      const result = res.data
      if (result) {
        setManualData(result);
        setPaynow1Url(result?.paynow1Url);
        setPayOneImage(result?.isPayOneImage);
        setPayTwoImage(result?.isPayTwoImage);
        setPayThreeImage(result?.isPayThreeImage);
        setPayFourImage(result?.isPayFourImage);
        setPayFiveImage(result?.isPayFiveImage);
        setIsMyPayActive(result?.isMypayInActive)
        setIsKvmPayActive(result?.isKvmPayActive)
        setUpigateway(result?.upigateway)
        setupigateway1(result?.upigateway1)
        setUpigatewayGK(result?.upigatewayGk)
      }
    } catch (error) {
      console.log("error", error);
    }

    // var requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,
    //   redirect: "follow",
    // };

    // fetch(baseUrl + "gatewaysettings/data", requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result.upigateway);
    //     setManualData(result);
    //     setPaynow1Url(result?.paynow1Url);
    //     setPayOneImage(result?.isPayOneImage);
    //     setPayTwoImage(result?.isPayTwoImage);
    //     setPayThreeImage(result?.isPayThreeImage);
    //     setPayFourImage(result?.isPayFourImage);
    //     setPayFiveImage(result?.isPayFiveImage);
    //     setIsMyPayActive(result?.isMypayInActive)
    //     setIsKvmPayActive(result?.isKvmPayActive)
    //     setUpigateway(result?.upigateway)
    //     setUpigatewayGK(result?.upigatewayGk)
    //   })
    //   .catch((error) => {

    //   });
  };

  useEffect(() => {
    ManualPayment();
  }, []);

  // getImage URl
  const [ImageUrl, setImageUrl] = useState("");
  const [PaymentLink, setPaymentLink] = useState("");
  const MyData = localStorage.getItem('meData')
  const meedata = JSON.parse(MyData)

  const getImageUrl = (image, url) => {

    setImageUrl(image);
    setPaymentLink(url);
  };

  // code link payment

  const copyCode = (e) => {
    navigator.clipboard.writeText(PayNow1Url);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Link Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };



  const copyCode1 = (e) => {
    navigator.clipboard.writeText(e);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Copy SuccessFully",
      showConfirmButton: false,
      timer: 1200,
    });
  };



  // kvm pay

  const kvmpay = async () => {
    setProcess(true);
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`kvmpay/payin`, "POST", { amount: global }, headers)
      console.log(res.data?.qrcodE_STRING, "res");
      setProcess(false);
      if (res.data.status == false) {
        Swal.fire({
          title: res.data.msg,
          icon: "danger",
          confirmButtonText: "OK",
        });
      } else if (res.data) {
        let order_id = res.data.txnID;
        let order_token = res.data.paymentReferenceId;
        //setProcess(false);

        window.location.href = `${res?.data?.qrcodE_STRING}`;
        // setUpiLink(`${res?.data?.qrcodE_STRING}&am=${global}`);
        //    handleShow_won1();
        // setUpiLink(`${res?.data?.qrcodE_STRING}&am=${global}`);

        // window.open(`${res?.data?.qrcodE_STRING}&am=${global}`);
        // setTimeout(() => {
        //   checkupideposit(order_id, order_token);
        //   setProcess(false);
        // }, 30000);
      } else {
        setProcess(false);
        Swal.fire({
          title: res.data.status,
          icon: "danger",
          confirmButtonText: "OK",
        });
      }
    } catch (e) {
      setProcess(false);
      console.log(e, "error");

      if (e.response.status == 400 || e.response.status == 429) {
        Swal.fire({
          title: e.response?.data.msg,
          icon: "warning",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "error!! Please refresh and connect to admin",
          icon: "warning",
          confirmButtonText: "OK",
        });
      }
      alert(e);
    }
  };



  const ManualPayments = async () => {
    let access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    setIsloading1(true);


    var formdata = new FormData();
    formdata.append("Transaction_Screenshot", scrnshot);
    formdata.append("amount", global);
    formdata.append("referenceId", utr);
    if (acdata?._id) {
      formdata.append("bankId", acdata?._id)
    }

    try {
      const result = await apiRequest(`manual/deposit/txn`, "POST", formdata, headers)
      console.log(result.data.data)
      if (result) {
        setIsloading1(false);
        if (result.data.data?.status === "Pending") {
          Swal.fire({
            title: result.data.message,
            icon: "success",
            confirmButtonText: "OK",
          });
        } else {
          Swal.fire({
            title: result.data.message,
            icon: "error",
            confirmButtonText: "OK",
          });
        }

        history.push("/");
        handleClose_won();
      }
    } catch (e) {
      console.log("e", e)
    }
  };


  // language 
  const translations = {
    hi: {
      addMoney: 'पैसे जोड़ने के लिए राशि चुनें |',
      money: 'राशि डालें  ',
      next: 'आगे बड़े ',
      addAmount: 'राशि जोड़ी जानी है ',
      referPerson: 'रेफ़रल | ',
    },
    en: {
      addMoney: 'Choose Amount To Add',
      money: 'Enter Amount',
      next: 'Next',
      addAmount: 'Amount to be added',
      referPerson: 'Referral',
    },
  };

  const { language } = useContext(LanguageContext);

  // change theme 
  const theme = localStorage.getItem('theme')


  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
    // padding: '20px',
    // margin: '20px',
  };

  const bankList = async () => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    try {
      const res = await apiRequest(`get/allbank`, "GET", null, headers)
      setLoading(false)
      setAllBank(res.data?.data)
      console.log(allBank[0]?.minLimit)
    } catch (e) {
      setLoading(false)
      console.log(e)
    }
  }

  const myBank = (data) => {
    console.log(next)
    setacdata(data)
    setNext(4);
  }

  useEffect(() => {
    console.log(next)
    bankList()
  }, [])

  const udata = JSON.parse(localStorage.getItem("udata"));
  const isKYCCompleted = udata?.verified; // Check if KYC is completed

  const handleSetAmount = (amount) => {
    if (amount > addCaseSetting?.depositKyc && !isKYCCompleted) {
      alert("KYC completion is required for deposits above 500.");
      return;
    }
    setGlobal(amount);
  };
  return (
    <>
      <Header user={userAllData} />
      <div className="leftContainer mb_space" style={{ ...styles, height: "112vh" }}>
        <div className="pt-4 Orher_page_main_section">

          {Boolean(!process1) && (
            <div>
              {Boolean(next == 1) && (
                <div className="">
                  <h2 className="profile_headings" style={styles}>{translations[language].addMoney}</h2>
                  <div className="add_amount_main_box mt-4">
                    <div className="add_amount_main_box_inner">
                      <label className="label" style={styles}>{translations[language].money}</label>
                      <div className="enter_amount_input_box1 " >
                        <span className="img-size" >
                          <TbCoinRupeeFilled style={{ fontSize: "18px" }} />
                        </span>
                        <input
                          className="enter_amount_input"
                          type="tel"
                          id="amountInput"
                          value={global}
                          onChange={(e) => {
                            e.target.value > 0
                              ? e.target.value <= 50000
                                ? setGlobal(parseInt(e.target.value))
                                : setGlobal(50000)
                              : e.target.value < 0
                                ? setGlobal(10)
                                : setGlobal(0);
                          }}
                        />
                        <div className="d-flex align-items-center justify-content-between mt-2">
                          <small>Min: {addCaseSetting?.depositlimitMin}</small>
                          <small>Max: {addCaseSetting?.depositlimitMax}</small>

                        </div>
                      </div>



                    </div>

                    <div className="add_amount_buttons_main">
                      {[100, 250, 500, 2000].map((amount) => (
                        <button key={amount} onClick={() => handleSetAmount(amount)}>
                          <span>
                            <TbCoinRupeeFilled style={{ fontSize: "18px" }} />
                          </span>
                          <span>{amount}</span>
                        </button>
                      ))}
                    </div>

                    <div className="add_cash_nest_btn">
                      {addCaseSetting.isDeposit ? (
                        <button type="button" id="addcase" className="" onClick={handleNext}>
                          {translations[language].next} <BsArrowRight className="icons" />
                        </button>
                      ) : (
                        <>
                          <div className='gameCard pt-2' >
                            <div className='alrt_msg_div' >
                              <h5 className=''>
                                {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                                  ? ''
                                  : JSON.parse(localStorage.getItem('sitSetting'))
                                    ?.deposit_message}
                              </h5>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {Boolean(next != 1) && (

                <div className="pb-3">
                  <div className={`${css.games_section}`}>
                    <div className="d-flex position-relative align-items-center justify-content-between">
                      <div className="add_cash_small_haedings">
                        {translations[language].addAmount}
                        ₹
                        <b>{global}</b>
                      </div>
                      <button
                        type="button"
                        onClick={() => setNext(1)}
                        className="samll_btn"
                      >
                        Edit
                      </button>
                    </div>
                  </div>
                </div>
              )}

              {Boolean(next == 2) && (
                <>
                  {global > addCaseSetting?.depositKyc && meedata?.verified != "verified" ?
                    <Link to='/profile'>
                      <div style={{ height: '100px' }} className='kyc_page_cards'>
                        <picture className='ml-3'>
                          <img
                            src='/images/alert.svg'
                            alt=''
                            width='32px'
                            className=''
                          />
                        </picture>
                        <p className='ml-1  mytext '>
                          Complete KYC to take Withdrawals Click here
                        </p>
                      </div>
                    </Link> :
                    <div className="">
                      <>

                        <div className="mt-3">
                          <div className="profile_headings">
                            Pay Through QR CODE
                          </div>

                          <section className="win_modal">
                            <Modal
                              show={show_won1}
                              onHide={handleClose_won1}
                              className="cancel_popup_reason_popup"
                              aria-labelledby="contained-modal-title-vcenter"
                              centered
                            >
                              <Modal.Header closeButton></Modal.Header>
                              <Modal.Body>
                                {upiLink && (
                                  <div>
                                    <QRCode value={upiLink} size={256} />
                                  </div>
                                )}


                                <button type="button" className="close-button " onClick={handleClose_won1}>Close</button>

                              </Modal.Body>
                            </Modal>
                          </section>


                          {/* Upi Gateway pay  */}
                          {Boolean(upigateway) && global >= manualData?.upiLimitMin && global <= manualData?.upiLimitMax ? (
                            <div
                              onClick={() => deposit()}
                              className="add-fund-box mt-3"
                              style={{ paddingTop: "0px", height: "60px" }}
                            >
                              {isLoading ? (
                                <div
                                  className="loaderReturn"
                                  style={{ zIndex: "99" }}
                                >
                                  <img
                                    src={"/images/LandingPage_img/loader1.gif"}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="d-flex align-items-center bg-upi"
                                  style={{

                                    border: "1px solid #e0e0e0",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      width="40px"
                                      src="/UPI.png"
                                      alt=""
                                      style={{
                                        marginLeft: "7px",
                                        paddingBottom: "10px",
                                        paddingLeft: "3px",
                                        paddingTop: "5px",
                                        filter: " brightness(10.5)"
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center flex-column ml-4">
                                    <div className="jss30">
                                      <strong style={{ color: "white" }}>PAY UPI</strong>
                                    </div>
                                    <div className="jss31"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : ""}
                          {Boolean(upigateway1) && global >= manualData?.upiLimitMin1 && global <= manualData?.upiLimitMax1 ? (
                            <div
                              onClick={() => depositUpi()}
                              className="add-fund-box mt-3"
                              style={{ paddingTop: "0px", height: "60px" }}
                            >
                              {isLoading ? (
                                <div
                                  className="loaderReturn"
                                  style={{ zIndex: "99" }}
                                >
                                  <img
                                    src={"/images/LandingPage_img/loader1.gif"}
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="d-flex align-items-center bg-upi"
                                  style={{

                                    border: "1px solid #e0e0e0",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      width="40px"
                                      src="/UPI.png"
                                      alt=""
                                      style={{
                                        marginLeft: "7px",
                                        paddingBottom: "10px",
                                        paddingLeft: "3px",
                                        paddingTop: "5px",
                                        filter: " brightness(10.5)"
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center flex-column ml-4">
                                    <div className="jss30">
                                      <strong style={{ color: "white" }}>PAY UPI</strong>
                                    </div>
                                    <div className="jss31"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : ""}


                          {/*Kvm pay  */}
                          {global >= manualData?.KvmpayMin && Boolean(isKvmPayActive) && global <= manualData?.KvmpayMax ? (
                            <div
                              onClick={() => kvmpay()}
                              className="add-fund-box bg-upi mt-3"
                              style={{ paddingTop: "0px", height: "60px" }}
                            >
                              {isLoading ? (
                                <div className="loaderReturn" style={{ zIndex: "99" }}>
                                  <img
                                    src="/images/LandingPage_img/loader1.gif"
                                    style={{ width: "100%" }}
                                  />
                                </div>
                              ) : (
                                <div
                                  className="d-flex align-items-center"
                                  style={{
                                    backgroundColor: "#fafafa",
                                    border: "1px solid #e0e0e0",
                                    borderRadius: "7px",
                                  }}
                                >
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      height: "60px",
                                      display: "flex",
                                      textAlign: "center",
                                    }}
                                  >
                                    <img
                                      width="40px"
                                      src="/UPI.png"
                                      alt="UPI Icon"
                                      style={{
                                        marginLeft: "7px",
                                        paddingBottom: "10px",
                                        paddingLeft: "3px",
                                        paddingTop: "5px",
                                      }}
                                    />
                                  </div>
                                  <div className="d-flex justify-content-center flex-column ml-4">
                                    <div className="jss30">
                                      <strong style={{ color: "white" }}>Pay Now</strong>
                                    </div>
                                    <div className="jss31"></div>
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : null}




                          {/* pay account  */}

                          <>
                            {allBank?.map((item, index) => (
                              <div>
                                {console.log(item?.maxLimit, item.minLimit, global >= item?.minLimit, global <= item?.maxLimit)}
                                {item?.status &&
                                  global >= item?.minLimit &&
                                  global <= item?.maxLimit ? (
                                  <div >

                                    <div
                                      onClick={() => myBank(item)}
                                      className="add-fund-box mt-3"
                                      style={{ paddingTop: "0px", height: "60px", cursor: "pointer" }}
                                    >

                                      <div
                                        className="d-flex  bg-upi align-items-center"
                                        style={{
                                          // backgroundColor: "#fafafa",
                                          border: "1px solid #e0e0e0",
                                          borderRadius: "7px",
                                        }}
                                      >
                                        <div
                                          className="d-flex align-items-center"
                                          style={{
                                            height: "60px",
                                            display: "flex",
                                            textAlign: "center",
                                          }}
                                        >
                                          <img
                                            width="40px"
                                            src="/Bank.png"
                                            alt=""
                                            style={{
                                              marginLeft: "7px",
                                              paddingBottom: "10px",
                                              paddingLeft: "3px",
                                              paddingTop: "5px",
                                            }}
                                          />
                                        </div>
                                        <div className="d-flex justify-content-center flex-column ml-4">
                                          <div className="jss30">
                                            <strong style={{ color: "white" }}>PAY WITH ANY UPI APPS</strong>
                                          </div>
                                          <div className="jss31"></div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            ))}
                          </>


                          <div className="Qrcode">
                            {/* Qr code 1 */}

                            {manualData?.isPayNowOne && global >= manualData?.upiMin && global <= manualData?.upiMax ? (
                              <div
                                onClick={() => {
                                  setNext(3);
                                  getImageUrl(PayOneImage, PayNow1Url);
                                }}
                                // onClick={() => fetchPaymentUrl()}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px", cursor: "pointer" }}
                              >
                                {isLoading ? (
                                  <div
                                    className="loaderReturn"
                                    style={{ zIndex: "99" }}
                                  >
                                    <img
                                      src={"/images/LandingPage_img/loader1.gif"}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "7px",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        width="40px"
                                        src="/UPI.png"
                                        alt=""
                                        style={{
                                          marginLeft: "7px",
                                          paddingBottom: "10px",
                                          paddingLeft: "3px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center flex-column ml-4">
                                      <div className="jss30">
                                        <strong style={{ color: "black" }}>QR CODE 1</strong>
                                      </div>
                                      <div className="jss31"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Qr code 2 */}
                            {manualData?.isPayNowTwo ? (
                              <div
                                onClick={() => {
                                  setNext(3);
                                  getImageUrl(PayTwoImage, PayNow2Url);
                                }}
                                // onClick={() => fetchPaymentUrl()}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px" }}
                              >
                                {isLoading ? (
                                  <div
                                    className="loaderReturn"
                                    style={{ zIndex: "99" }}
                                  >
                                    <img
                                      src={"/images/LandingPage_img/loader1.gif"}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "7px",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        width="40px"
                                        src="/UPI.png"
                                        alt=""
                                        style={{
                                          marginLeft: "7px",
                                          paddingBottom: "10px",
                                          paddingLeft: "3px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center flex-column ml-4">
                                      <div className="jss30">
                                        <strong>QR CODE 2</strong>
                                      </div>
                                      <div className="jss31"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Qr code 3 */}
                            {manualData?.isPayNowThree ? (
                              <div
                                onClick={() => {
                                  setNext(3);
                                  getImageUrl(PayThreeImage, PayNow3Url);
                                }}
                                // onClick={() => fetchPaymentUrl()}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px" }}
                              >
                                {isLoading ? (
                                  <div
                                    className="loaderReturn"
                                    style={{ zIndex: "99" }}
                                  >
                                    <img
                                      src={"/images/LandingPage_img/loader1.gif"}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "7px",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        width="40px"
                                        src="/UPI.png"
                                        alt=""
                                        style={{
                                          marginLeft: "7px",
                                          paddingBottom: "10px",
                                          paddingLeft: "3px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center flex-column ml-4">
                                      <div className="jss30">
                                        <strong>QR CODE 3</strong>
                                      </div>
                                      <div className="jss31"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Qr code 4 */}
                            {manualData?.isPayNowFour ? (
                              <div
                                onClick={() => {
                                  setNext(3);
                                  getImageUrl(PayFourImage, PayNow4Url);
                                }}
                                // onClick={() => fetchPaymentUrl()}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px" }}
                              >
                                {isLoading ? (
                                  <div
                                    className="loaderReturn"
                                    style={{ zIndex: "99" }}
                                  >
                                    <img
                                      src={"/images/LandingPage_img/loader1.gif"}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "7px",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        width="40px"
                                        src="/UPI.png"
                                        alt=""
                                        style={{
                                          marginLeft: "7px",
                                          paddingBottom: "10px",
                                          paddingLeft: "3px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center flex-column ml-4">
                                      <div className="jss30">
                                        <strong>QR CODE 4</strong>
                                      </div>
                                      <div className="jss31"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}

                            {/* Qr code 5 */}
                            {manualData?.isPayNowFive ? (
                              <div
                                onClick={() => {
                                  setNext(3);
                                  getImageUrl(PayFiveImage, PayNow5Url);
                                }}
                                className="add-fund-box mt-3"
                                style={{ paddingTop: "0px", height: "60px" }}
                              >
                                {isLoading ? (
                                  <div
                                    className="loaderReturn"
                                    style={{ zIndex: "99" }}
                                  >
                                    <img
                                      src={"/images/LandingPage_img/loader1.gif"}
                                      style={{ width: "100%" }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className="d-flex align-items-center"
                                    style={{
                                      backgroundColor: "#fafafa",
                                      border: "1px solid #e0e0e0",
                                      borderRadius: "7px",
                                    }}
                                  >
                                    <div
                                      className="d-flex align-items-center"
                                      style={{
                                        height: "60px",
                                        display: "flex",
                                        textAlign: "center",
                                      }}
                                    >
                                      <img
                                        width="40px"
                                        src="/UPI.png"
                                        alt=""
                                        style={{
                                          marginLeft: "7px",
                                          paddingBottom: "10px",
                                          paddingLeft: "3px",
                                          paddingTop: "5px",
                                        }}
                                      />
                                    </div>
                                    <div className="d-flex justify-content-center flex-column ml-4">
                                      <div className="jss30">
                                        <strong>QR CODE 5</strong>
                                      </div>
                                      <div className="jss31"></div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </>
                    </div>
                  }
                </>
              )}


              {Boolean(next == 3) && (
                <>
                  <div class="alrt_msg_div">
                    <h5 class="">
                      अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ डिपॉजिट ऐड नहीं होगा |
                    </h5>
                  </div>
                  <img
                    src={baseUrl + `${ImageUrl}`} // Replace with the actual path to your image file
                    alt="QR Image"
                    style={{ width: "90%", display: "block", margin: "auto" }}
                  />
                  {/* <div>
                    <DownloadLink
                      className="results_btn results_btn_cancel mt-3"
                      style={{ width: "50%" }}
                      label=" Download QR"
                      filename={baseUrl + `${ImageUrl}`}
                      exportFile={() => "My cached data"}
                    />
                  </div> */}

                  <div className="Refer_code">
                    <h3 style={styles}>{PayNow1Url}</h3>
                    <button onClick={(e) => copyCode(e)}>Copy</button>
                  </div>

                  <div>
                    <button
                      className="results_btn results_btn_win mt-2"
                      onClick={handleShow_won}
                    >
                      {" "}
                      Upload Payment Details
                    </button>
                  </div>
                </>
              )}
            </div>
          )}

          {Boolean(next == 4) && (
            <>

              <div class="alrt_msg_div" >
                <h5 class="">
                  अगर कोई यूजर मैन्युअल डिपॉजिट अमाउंट जितना पे करता है उतना ही पेमेंट डालकर UTR नंबर और स्क्रीनशॉट सबमिट करें अगर आप पेमेंट कम या और ज्यादा डालते हो आपका किया हुआ डिपॉजिट ऐड नहीं होगा |
                </h5>
              </div>
              {
                acdata?.accountNumber ?
                  <>
                    <div class="alrt_deposit" >
                      <div className="Refer_code" >
                        <p style={{ ...styles, fontSize: "20px" }}>
                          Bank Name :  <span style={{ color: 'red' }} className="mb-4">
                            {acdata?.bankName}
                          </span></p>
                      </div>

                    </div>
                    <div class="alrt_deposit" >
                      <div className="Refer_code">
                        <p style={{ ...styles, fontSize: "20px" }}>
                          Account Number :  <span style={{ color: 'red' }} className="mb-4">
                            {acdata?.accountNumber}
                          </span></p>
                        <button onClick={() => copyCode1(acdata?.accountNumber)}>Copy</button>
                      </div>

                    </div>
                    <div class="alrt_deposit" >
                      <div className="Refer_code">
                        <p style={{ ...styles, fontSize: "20px" }}>
                          Beneficiary Name :  <span style={{ color: 'red' }} className="mb-4">
                            {acdata?.accountHolderName}
                          </span></p>
                        <button onClick={() => copyCode1(acdata?.accountHolderName)}>Copy</button>
                      </div>

                    </div>
                    <div class="alrt_deposit" >
                      <div className="Refer_code">
                        <p style={{ ...styles, fontSize: "20px" }}>
                          IFSC Code :  <span style={{ color: 'red' }} className="mb-4">
                            {acdata?.ifscCode}
                          </span></p>
                        <button onClick={() => copyCode1(acdata?.ifscCode)}>Copy</button>
                      </div>

                    </div>
                  </>
                  : ""
              }
              {
                acdata?.upiId ?
                  <div class="alrt_deposit" >
                    <div className="Refer_code">
                      <p style={{ ...styles, fontSize: "20px" }}>
                        UPI ID :  <span style={{ color: 'red' }} className="mb-4">
                          {acdata?.upiId}
                        </span></p>
                      <button onClick={() => copyCode1(acdata?.upiId)}>Copy</button>
                    </div>

                  </div>
                  : ""}

              <div>
                <p>
                  NOTE :- पेमेंट सक्सेस होने के बाद निचे दिय गए बटन पर क्लिक करके स्क्रीनशॉट और UTR नंबर डालके सबमिट कर दे |
                </p>
              </div>

              <div className="mb-5 d-flex justify-content-center">
                <button
                  className="results_btn results_btn_win mt-2"
                  onClick={handleShow_won}
                >
                  {" "}
                  Upload Payment Details
                </button>
              </div>
            </>
          )}

          {Boolean(process1) && (
            <div className="loaderReturn" style={{ zIndex: "99" }}>
              <img
                src={"/images/LandingPage_img/loader1.gif"}
                style={{ width: "100%" }}
              />
            </div>
          )}
        </div>
      </div>

      <section className="win_modal">
        <Modal
          show={show_won}
          onHide={handleClose_won}
          className="cancel_popup_reason_popup"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <div>
              <h3>Payment Details</h3>
              <div>
                <lable className="commaon_label">AMOUNT</lable>
                <input
                  className="commaon_input_box mb-2"
                  type="number"
                  required
                  value={global}
                  onChange={(e) => setGlobal(e.target.value)}
                  placeholder="Enter amount"
                  readOnly
                />
                <lable className="commaon_label mt-4">UTR NUMBER</lable>
                <input
                  className="commaon_input_box"
                  type="text"
                  required
                  value={utr}
                  onChange={(e) => setutr(e.target.value)}
                  placeholder="Enter UTR Number"
                />
              </div>

              <div className={`${css1.doc_upload} mt-4 upload_ss_btn`}>
                <input
                  type="file"
                  onChange={handleChange}
                  accept="image/*"
                  required
                />
                {!scrnshot && (
                  <div className="cxy flex-column position-absolute ">
                    <FaArrowUp style={{ color: "#fff" }} />
                    <div
                      className={`${css1.sideNav_text} mt-2 upload_ss_btn_name`}
                    >
                      Upload screenshot.
                    </div>
                  </div>
                )}
                {scrnshot && (
                  <div className={css1.uploaded}>
                    <img
                      src="/images/file-icon.png"
                      width="26px"
                      alt=""
                      style={{ marginRight: "20px" }}
                    />
                    <div
                      className="d-flex flex-column"
                      style={{ width: "80%" }}
                    >
                      <div
                        className={`${css1.name} `}
                        style={{ color: "#fff" }}
                      >
                        {scrnshot.name}
                      </div>
                      <div className={css1.size}>
                        {(scrnshot.size / 1024 / 1024).toFixed(2)} MB
                      </div>
                    </div>
                    <div className="image-block">
                      <img
                        src="/images/global-cross.png"
                        width="10px"
                        alt=""
                        onClick={() => {
                          clearImage();
                          handleClose_won();
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>

              <div style={{ width: "100%", marginTop: "20px" }}>
                <img
                  src={scrnshot1}
                  style={{ width: "100%" }}
                  className="screenshot_img"
                  required
                />
              </div>

              {/* {fecthStatus == null && fecthStatus == undefined && (
                <input
                  type="submit"
                  value="Submit"
                  className="btn btn-danger mt-3 text-white results_btn w-100"
                  id="post"
                  // onClick={(e) => {
                  //   Result(e);
                  // }}
                  onClick={ManualPayments}
                  disabled={!scrnshot}
                />
              )} */}

              {fecthStatus == null && fecthStatus == undefined && (
                <>
                  {isLoading1 ? (
                    <button className="Login-button cxy" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </button>
                  ) : (
                    <input
                      type="submit"
                      value="SUBMIT"
                      className="btn btn-danger mt-3 text-white results_btn w-100"
                      id="post"
                      onClick={ManualPayments}
                      disabled={!utr}
                      style={{ fontSize: "20px !important" }}
                    />
                  )}
                </>
              )}
            </div>
          </Modal.Body>
        </Modal>
      </section>

      <div className="rightContainer">
        <Rightcontainer />
      </div>
    </>
  );
};

export default Addcase;
