import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';


const SliderCode = () => {

    return (
        <>
            <div class="slider">
                <div class="slides">
                    <div class="slide slide1">
                        <img src='/hindienglish.jpg' />
                    </div>
                    <div class="slide slide3">
                        <img src='/offer.jpg' />
                    </div>
                    <div class="slide slide2">
                        <img src='/hindienglish.jpg' />
                    </div>
                </div>
            </div>
        </>
    )
};

export default SliderCode;