import React, { useEffect, useState } from "react";
import { Interweave } from "interweave";
import axios from "axios";
import { apiRequest } from "../store/allApi";

function AboutUs() {
  const [data, setData] = useState();
  // BASE URL

  // const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  // const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const nodeMode = process.env.NODE_ENV;
  // if (nodeMode === "development") {
  //   var baseUrl = beckendLocalApiUrl;
  // } else {
  //   baseUrl = beckendLiveApiUrl;
  // }

  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // +++++++++++++++++++++++++++++
  const getdata =async () => {

    try {
      const res = await apiRequest(`api/term/condition/About-Us`,"GET",null)
      setData(res.data[0]?.Desc);
    } catch (e) { 
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return (
    <div className="leftContainer mb_space">
      <div className="pt-3  Orher_page_main_section">
        <h1 className="profile_headings">About Us</h1>
        <div>
          NKADDA  WEBTECH PRIVATE LIMITED NKADDA LUDO, is a real-time online
          LUDO card game which fully owned by NKADDA  WEBTECH PRIVATE LIMITED.
          We are a passionate team of designers and developers with years
          experience in the card game industry. Our aim is to provide you an
          awesome online experience on this sophisticated handmade product!
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
