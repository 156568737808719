import React, { useState, useEffect, useRef } from "react";
import css from "../css/gamehis.module.css";
import { Button } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import axios from "axios";
import Invoice from "./Invoice";
import { FaEye } from "react-icons/fa";
import { AiOutlineDownload } from "react-icons/ai";
import "./invoice.css";
import socket from "../Components/socket";
import { BsFillEyeFill } from "react-icons/bs";
import { apiRequest } from "../store/allApi";

const DepositHistory = () => {

  // BASE URL

  // const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  // const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const nodeMode = process.env.NODE_ENV;
  // if (nodeMode === "development") {

  //   var baseUrl = beckendLocalApiUrl;
  // } else {
  //   baseUrl = beckendLiveApiUrl;
  // }
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // +++++++++++++++++++++++++++++ 
  let printRef = useRef(null);

  const [show, setShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setInvoiceData(e);
  };

  const [user, setUser] = useState("");

  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      setUser(res.data);

      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    } catch (e) {
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);

  };

  // ALL APIS HERE

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setIsLoading(true);

    try {
      const res = await apiRequest(`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, "GET", null, headers)
      setGame(res.data.ress);
      {
        // console.log(res.data.ress);
      }

      setNumberOfPages(res.data.totalPages);
      setIsLoading(false);
    } catch (e) {
    }
  };



  useEffect(() => {
    console.log(cardData)
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  const [Withdrawal, setWithdrawal] = useState();
  const [showBottom, setShowBottom] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = (data) => {
    setShowBottom(true);
    setWithdrawal(data);
  };

  // const dateFormat = (e) => {

  //   const date = new Date(e);
  //   const newDate = date.toLocaleString("default", {
  //     month: "short",
  //     day: "numeric",
  //     hour: "numeric",
  //     hour12: true,
  //     minute: "numeric",
  //   });
  //   return newDate;
  // };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${newDate}, ${time}`;
  };

  return (
    <>
      <div>

        {/* game-cards */}

        {isLoading ? (
          <div className="text-center">
            {cardData && cardData.length === 0 ? (
              <div className="mt-2">
                <h3 className="font-weight-bold">No transaction History</h3>
                <p className="text-muted">
                  You have not made any transaction yet.
                </p>
              </div>
            ) : (
              <div
                className="loaderReturn text-center"
                style={{ zIndex: "99" }}
              >
                <img
                  src={"/images/LandingPage_img/loader1.gif"}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          </div>
        ) : (
          cardData &&
          cardData.length === 0 && (
            <div className="mt-2 text-center">
              <h3 className="font-weight-bold">No transaction History</h3>
              <p className="text-muted">
                You have not made any transaction yet.
              </p>
            </div>
          )
        )}

        {/* new code history  */}

        <div className="transaction_main_section_modal">
          {cardData &&
            cardData
              .filter((card) => card?.Req_type?.toLowerCase().trim() === "deposit")
              .map((card) => {
                var id = card._id.toString(),
                  ctr = 0;
                var timestamp = id.slice(ctr, (ctr += 8));
                var machineID = id.slice(ctr, (ctr += 6));
                var processID = id.slice(ctr, (ctr += 4));
                var counter = id.slice(ctr, (ctr += 6));

                var counterid = parseInt(timestamp, 16);

                var titleMsg = "deposit"; // Always show "Penalty"
                if (
                  (card.Req_type === "deposit" ||
                    card.Req_type === "bonus") &&
                  card.status != "FAILED"
                ) {
                  var titleMsg = "Cash added";
                  if (
                    card.status === "Pending" &&
                    card.Req_type === "deposit"
                  ) {
                    var signIcon = <div className="text-danger">(X)</div>;
                  } else {
                    var signIcon = (
                      <div className="text-success">(+)</div>
                    );
                  }
                } else if (
                  card.Req_type === "withdraw" &&
                  card.status != "FAILED"
                ) {
                  var titleMsg = "Witdraw using " + card.Withdraw_type;
                  var signIcon = <div className="text-danger">(-)</div>;
                } else if (
                  card.Req_type === "penalty" &&
                  card.status != "FAILED"
                ) {
                  var titleMsg = "Penalty";
                  var signIcon = <div className="text-danger">(-)</div>;
                } else if (
                  card.status === "Pending" ||
                  card.status === "FAILED"
                ) {
                  var titleMsg = "FAILED";
                  var signIcon = <div className="text-danger">(X)</div>;
                } else {
                  var titleMsg = "";
                  var signIcon = <div className="text-success"></div>;
                }

                return (
                  <>
                    <div className="other_pages_card_main bg-white">
                      <div className="card_date text-center border-right pr-2">
                        {/* <p>{dateFormat(card.createdAt).split(",")[0]}</p>
                          <p>{dateFormat(card.createdAt).split(",")[1]}</p> */}
                        {dateFormat(card.createdAt).split(",")[0]}  <br /> {dateFormat(card.createdAt).split(",")[1]}
                      </div>
                      <div className="ml-3">
                        <h3 className="font-w700">{titleMsg}</h3>
                        {/* <h3>
                          Order ID:{" "}
                          <span className="dark_light_color"> {counterid}</span>
                        </h3> */}
                        <p className="ml-3">
                          {" "}
                          <span
                            style={{
                              fontWeight: "bold", padding: "4px 8px", borderRadius: "6px",
                              background:
                                card.status === "PAID"
                                  ? "green"
                                  : card.status === "FAILED"
                                    ? "red"
                                    : "#FFF9C4",
                              color:
                                card.status === "PAID" || card.status === "FAILED"
                                  ? "#fff"
                                  : "#000",
                            }}
                          >
                            {card.status === "none" ||
                              (card.status === "Pending" && card.Req_type === "withdraw")
                              ? "Processing"
                              : card.status === "Pending" && card.Req_type === "deposit"
                                ? "Cancelled"
                                : card.status}
                          </span>
                        </p>
                        <p className="dander_text transaction_msg mt-2 " style={{ fontWeight: '700' }}>
                          {" "}
                          {card.txn_msg ? card.txn_msg : ""}
                        </p>
                      </div>

                      <div className="transaction_right_side_main">
                        <div className="card_rupee d-flex float-right font-w700">

                          {signIcon}
                          <picture className="">
                            <img
                              alt="img"
                              height="21px"
                              width="21px"
                              src={
                                process.env.PUBLIC_URL +
                                "/nkcoin.png"
                              }
                              className="snip-img"
                            />
                          </picture>
                          <span className="pl-1 font-w700">{card.amount}</span>
                          {card.status === "PAID" && (
                            <img style={{ width: '16px', marginLeft: '5px' }} src="/images/check.png" alt="Success" />
                          )}
                        </div>
                        <div>
                          {card.closing_balance && (
                            <div
                              className={`${css.games_section_headline} gamehis_games_section_headline__BVYUG `}
                              style={{ fontSize: "12px", marginTop: "8px", whiteSpace: "nowrap", color: "#757575", }}
                            // style={{
                            //   fontSize: "12px",
                            //   whiteSpace: "nowrap",
                            // }}
                            >
                              Cl Bal : ₹ {card.closing_balance}
                            </div>
                          )}
                        </div>

                      </div>
                    </div>
                  </>
                );
              })}
        </div>
        <div>

        </div>
      </div>


    </>
  );
};

export default DepositHistory;
