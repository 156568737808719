import React from 'react'
import { NavLink } from 'react-router-dom'
import { SlHome } from 'react-icons/sl'
import { BsWallet, BsGift } from 'react-icons/bs'
import { FiUser, FiUsers } from 'react-icons/fi'
import { FaHistory } from "react-icons/fa";
import { AiOutlineMessage } from 'react-icons/ai'
import { BiMessageSquareDetail } from 'react-icons/bi'

const BottonMenu = ({ w3_close }) => {
  return (
    <div className='main_bottom_menus'>
      <nav className='bottom_link_main'>
        <NavLink exact to='/landing' className='bottom_link' activeClassName='active_link' onClick={w3_close}>
          <span>Home</span>
        </NavLink>

        <NavLink exact to='/wallet' className='bottom_link' activeClassName='active_link' onClick={w3_close}>
          <span>My wallet</span>
        </NavLink>

        <NavLink exact to='/refer' className='bottom_link' activeClassName='active_link' onClick={w3_close}>
          <span>Refer</span>
        </NavLink>

        <NavLink exact to='/history' className='bottom_link' activeClassName='active_link' onClick={w3_close}>
          <span>History</span>
        </NavLink>

        <NavLink exact to='/Profile' className='bottom_link' activeClassName='active_link' onClick={w3_close}>
          <span>Account</span>
        </NavLink>
      </nav>
    </div>
  )
}

export default BottonMenu
