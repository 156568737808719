import React, { useEffect, useState ,useContext} from 'react'
import axios from 'axios'
import socket from '../Components/socket'
import Swal from "sweetalert2";
import { FaRegUserCircle } from "react-icons/fa";
import { CiMoneyBill } from "react-icons/ci";
import { LanguageContext } from "../Components/LanguageContext";
import { apiRequest } from '../store/allApi';
const Referralhis = () => {
  const [user, setUser] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const { language } = useContext(LanguageContext);
  const theme = localStorage.getItem('theme')
  
  const addsetting = localStorage.getItem("sitSetting");
  const addCaseSetting = JSON.parse(addsetting);

  // const [cardData, setGame] = useState([]);
  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
  };


  const copyCode = (e) => {
    navigator.clipboard.writeText(user.referral_code);

    Swal.fire({
      position: "center",
      icon: "success",
      type: "success",
      title: "Referral Code Copied",
      showConfirmButton: false,
      timer: 1200,
    });
  };


  const translations = {
    hi: {
      referral: 'आपकी रेफरल कमाई',
      referPerson: 'आमंत्रित किये दोस्त | ',
      referEarn: 'रेफरल कमाई  ',
      referCode: 'रेफरल कोड ',
    },
    en: {
      referral: 'Lifetime Earnings',
      referPerson: 'Referred Players',
      referEarn: 'Referral Earning',
      referCode: 'Referral Code',
    },
  };

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++ 

  

  const role = async () => {
    socket.emit('getprofile')

    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    try {
      const res = await apiRequest(`me` , "GET", null , headers)
      setUser(res.data)

      Allgames(res.data.referral_code)
      // window.location.reload()
    } catch (e) { 
    }
  }

  const [cardData, setGame] = useState([])
  // console.log(cardData)

  const Allgames = async id => {
    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    setIsLoading(true)

    try {
      const res = await apiRequest(`referral/code/winn/${id}`, "GET", null, headers)
      setGame(res.data)
      // console.log(res.data);
      setIsLoading(false)
    } catch (e) { 
    }
  }

  // const dateFormat = e => {
  //   const date = new Date(e)
  //   const newDate = date.toLocaleString('default', {
  //     month: 'long',
  //     day: 'numeric',
  //     hour: 'numeric',
  //     hour12: true,
  //     minute: 'numeric'
  //   })
  //   return newDate
  // }
  
  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });
  
    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  
    return `${newDate}, ${time}`;
  };
  useEffect(() => {
    role()
    //eslint-disable-next-line
  }, [])

  if (cardData == undefined) {
    return null
  }

  return (
    <div>
      <div className="container mt-3">
        
      <div className="refer_page_top_main_box_outer ">
              <div className="refer_page_top_main_box">

                <h2 className="profile_headings text-dark text-left">{translations[language].referral}</h2>
              </div>
              <div className="refer_page_top_main_box_inner">
                <FaRegUserCircle size={30} style={{ color: "#007bff", margin: "" }} />
                <div className="refer_page_top_main_box_inner_single bo_le">

                  <h3 className="text-dark" style={styles}>{translations[language].referPerson}</h3>

                  <p className="mb-0" style={styles}>{user?.referral_user?.length}</p>
                  {/* {console.log(user)} */}
                </div>
                
                
                <CiMoneyBill size={30} style={{ color: "#007bff", marginLeft: "30px" }} />
                <div className="refer_page_top_main_box_inner_single">
                  <h3 style={styles}>{translations[language].referEarn}</h3>
                  <p className="mb-0" style={styles}>₹{user?.referral_earning}</p>
                </div>
              </div>
            </div>

        <div className="Orher_page_main_section mt-0">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "300px" }}>
              <img src="/images/LandingPage_img/loader1.gif" alt="Loading..." style={{ width: "50px" }} />
            </div>
          ) : cardData && cardData.length === 0 ? (
            <div className="text-center not_found_box_main">
              <img src="./images/not-found.gif" alt="No data" style={{ width: "150px" }} />
              <h4 className="font-weight-bold mt-2">No Referral History</h4>
              <p className="text-muted">You have no referral history yet.</p>
            </div>
          ) : (
            cardData &&
            cardData.map((card) => (

              <div
                className="card other_pages_card_main d-block text-center p-3 mb-3 shadow-sm"
                key={card._id}
                style={{
                  
                  background: "rgb(211 227 253)",
                  // borderLeft: card.winstatus ? "5px solid #28a745" : "5px solid #dc3545",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div>
                      <img className='refer_media_1' src="/images/icons/gift-card.png"/>
                  </div>
                  <div>
                    <h5 className=" mb-1 font-w700">Referral Earning</h5>
                    <p className="mb-1 text-muted font-w700" style={{ fontSize: "14px" }}>
                      Earned by: <strong>{card?.earned_from?.Name}</strong>
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <span className={card.winstatus ? "text-success" : "text-danger"} style={{ fontSize: "16px" }}>
                      {/* {card.winstatus ? "Win" : "Loss"} */}
                    </span>
                    <img src="/nkcoin.png" alt="" height="25px" width="25px" className="ml-2" />
                    <h5 className="pl-2 mb-0 text-success font-weight-bold">+{card.amount}</h5>
                  </div>
                </div>

                {/* Time & Date */}
                <div className="d-flex justify-content-between mt-2">
                <p className="text-dark fs-12 mb-0 font-w700" style={{ fontSize: "12px" }}>
                    {dateFormat(card.createdAt).split(",")[0]} | {dateFormat(card.createdAt).split(",")[1]}
                  </p>
                  <p className="gamehis_games_section_headline__BVYUG mb-0" style={{ fontSize: "14px" }}>
                    Cl Bal: {card.closing_balance}
                  </p>
                 
                </div>
              </div>
            ))
          )}
        </div>
      </div>

    </div>
  )
}

export default Referralhis
