import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import css from '../css/Addcase.module.css'
import { Link } from 'react-router-dom'
import Header from '../Components/Header'
import Rightcontainer from '../Components/Rightcontainer'
import Swal from 'sweetalert2'
import socket from '../Components/socket'
import { useTheme } from '../../ThemeContext'
import { apiRequest } from '../store/allApi'

const Redeem = ({ walletUpdate }) => {
  const history = useHistory()

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++ 

  const [amount, setamount] = useState()
  const [userAllData, setUserAllData] = useState()
  const role = async () => {
    socket.emit('getprofile')

    const access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      //console.log(res.data);
      setUserAllData(res.data)
    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('token')
      }
    }
  }

  useEffect(() => {
    let access_token = localStorage.getItem('token')
    access_token = localStorage.getItem('token')
    if (!access_token) {
      window.location.reload()
      history.push('/login')
    }
    role()
  }, [])

  const deposit = async() => {
    if (amount && amount >= 95 && amount <= 100000) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`referral/to/wallet`,"POST", {amount}, headers)
        
        if (res.data.msg === 'Invalid Amount') {
          Swal.fire({
            title: "You don't have sufficient amount",
            icon: 'warning',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: 'success',
            icon: 'success',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        if (e?.response?.status == 401) {
          localStorage.removeItem('token')
          localStorage.removeItem('token')
          window.location.reload()
          history.push('/login')
        }
      }
    } else {
      let msg = 'Enter Amount'
      if (!amount) {
        msg = 'Enter Amount'
      } else if (95 <= amount <= 100000) {
        msg = 'Amount should be more than 95.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  // change theme 
  const theme = localStorage.getItem('theme')
  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
  };

  return (
    <>
      <Header user={userAllData} />
      <div
        className='leftContainer mb_space'
        style={{ ...styles, minHeight: '100vh', height: '100%' }}
      >
        <div className='mt-5 Orher_page_main_section reed'>
          <img className="refral" style={{ width: "42px" }} src="/images/icons/gift-card.png" />
          <div className=''>
            <div className='profile_headings mt-5' style={styles}>
              Redeem your refer balance
            </div>
            <p className='commaon_para mt-2'>
              TDS (0%) will be deducted after annual referral earning of
              <img
                className='mx-1'
                src={
                  process.env.PUBLIC_URL +
                  '/nkcoin.png'
                }
                alt=''
                width='20x'
                height='20x'
              />{' '}
              15,000.
            </p>
          </div>
          <div className='add_amount_min_max'>
            <p> Enter Amount (Min: 100, Max: 10000)</p>
          </div>
          <div>
            <div className='MuiFormControl-root MuiTextField-root mt-4 '>
              <input
                class='commaon_input_box'
                type='number'
                style={{ width: '100%' }}
                value={amount}
                placeholder='Enter Amount'
                onChange={e => setamount(parseInt(e.target.value))}
              ></input>
              <div className='add_amount_min_max'>
                <p>
                  Minimum withdrawal amount{' '}
                  <img
                    className='mx-1'
                    src={
                      process.env.PUBLIC_URL +
                      '/nkcoin.png'
                    }
                    alt=''
                    width='20x'
                    height='20x'
                  />{' '}
                  95
                </p>
              </div>
              <p className='commaon_para'>
                Money will be added to NKADDA Coin.
              </p>
            </div>
            <div className='add_cash_nest_btn'>
              <button

                onClick={() => deposit()}
              >
                Redeem
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Redeem
