import React, { useState } from 'react';
import '../../app2.js/css/Deposit.css';
import axios from 'axios'
import Swal from "sweetalert2";
import { apiRequest } from '../store/allApi';



const Fund = () => {
  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {
    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }

  const [number, setNumber] = useState('');
  const [amount, setAmount] = useState('');
  const [reason, setReason] = useState('');

  const handleSubmit = async(e) => {
    e.preventDefault();
    const numericAmount = parseFloat(amount);
    const Phone = parseFloat(number);
    const access_token = localStorage.getItem("token")
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    try {
      const res = await apiRequest(`fund_transfer`,"POST",{ Phone: Phone,  amount: numericAmount,reason: reason}, headers)
      if(res.data){
        Swal.fire({
          position: "center",
          icon: "success",
          type: "success",
          title: res.data.msg,
          showConfirmButton: false,
          timer: 1200,
        });
      }
    } catch (e) {
      
    }
    // axios.post(`${baseUrl}fund_transfer`, {
    //   Phone: Phone,
    //   amount: numericAmount,
    //   reason: reason
    // }, { headers })
    //   .then((res) => {
    //     Swal.fire({
    //       position: "center",
    //       icon: "success",
    //       type: "success",
    //       title: res.data.msg,
    //       showConfirmButton: false,
    //       timer: 1200,
    //     });
    //   })
  };

  return (
    <div className="leftContainer fund-container">
      <form className="fund-form" onSubmit={handleSubmit}>
        <h2>Fund Transfer</h2>
        <div>
          <label htmlFor="number">Enter Number:</label>
          <input
            id="number"
            type="text"
            placeholder="Enter your number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="amount">Enter Amount:</label>
          <input
            id="amount"
            type="number"
            placeholder="Enter amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            required
          />
        </div>
        <div>
          <label htmlFor="amount">Enter Reason</label>
          <input
            id="Reason"
            type="text"
            placeholder="Enter Reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            required
          />
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export default Fund;
