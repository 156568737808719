import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// import { GoogleAuthProvider, signInWithPopup, getAuth } from 'firebase/auth';
import { getFirestore } from "firebase/firestore";
import { useEffect } from "react";
const axios = require("axios");

const firebaseConfig = {
  apiKey: "AIzaSyDPCgvnSk8WyO6KrDYfLyHCwwZqPd9P988",
  authDomain: "nkadda-ab00c.firebaseapp.com",
  projectId: "nkadda-ab00c",
  storageBucket: "nkadda-ab00c.firebasestorage.app",
  messagingSenderId: "758308593105",
  appId: "1:758308593105:web:ed1690eea0107a7d3c14f0",
  measurementId: "G-G-DERE6CRLRM",
};
// BASE URL
 
const baseUrl = process.env.REACT_APP_API_BASE_URL;
// +++++++++++++++++++++++++++++ 
const access_token = localStorage.getItem("token");
const fcm = localStorage.getItem("fcm");
export const app = initializeApp(firebaseConfig);

export const messaging = getMessaging(app);

const db = getFirestore(app);

export const getTokens = async (setTokenFound) => {
  try {
    const currentToken = await getToken(messaging, {
      vapidKey:
        "BFwWowecyp38AcnPsuY29GgPx9RZytrWEFS_mP5wqbtdQGOJkBLSAQp9xbBLDy7fkakqcNTOQZ2XW2BIcPZ3ERk",
    });
    console.log(currentToken,'vbvbvb')

    if (currentToken) {
      console.log(access_token , fcm !== currentToken)
      console.log(access_token)
      if (access_token && fcm !== currentToken) {
        const headers = {
          Authorization: `Bearer ${access_token}`,
        };
        try {
          const res = await axios.get(
            baseUrl+`/updateToken?firebaseToken=${currentToken}`,
            { headers }
          );
          console.log(res);
          localStorage.setItem("fcm", currentToken);
        } catch (e) {
          console.log(e);
        }
      }
      setTokenFound({ status: true, token: currentToken });
    } else {
      console.log(
        "No registration token available. Request permission to generate one."
      );
      setTokenFound(false);
    }
  } catch (err) {
    // console.log(err);
  }
};

getTokens();
export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
      console.log(payload);
    });
  });

// async function loginWithGoogle() {
//     try {
//         const provider = new GoogleAuthProvider();
//         const auth = getAuth();

//         const { user } = await signInWithPopup(auth, provider);

//         return { uid: user.uid, displayName: user.displayName };
//     } catch (error) {
//         if (error.code !== 'auth/cancelled-popup-request') {
//             console.error(error);
//         }

//         return null;
//     }
// }
// const provider=JSON.parse(localStorage.getItem('provider'));
//console.log(provider?.provider_data?.id)
// async function sendMessage(roomId, user, text) {
//     try {
//         await addDoc(collection(db, 'chatRoom', roomId, 'chats'), {
//             //   uid: user.uid,
//             //   displayName: user.displayName,
//             //   text: text.trim(),
//             //   timestamp: serverTimestamp(),
//             uid: user?.data?.id || null,
//             displayName: user?.data?.display_name || null,
//             user_type:user?.data?.user_type,
//             reciver_id:provider?.provider_data?.id,
//             text: text.trim(),
//             timestamp: serverTimestamp(),
//         });

//     } catch (error) {
//         console.error(error);
//     }
// }

// function getMessages(roomId, callback) {
//     return onSnapshot(
//         query(
//             collection(db, 'chatRoom', roomId, 'chats'),
//             orderBy('timestamp', 'asc')
//         ),
//         (querySnapshot) => {
//             const messages = querySnapshot.docs.map((doc) => ({
//                 id: doc.id,
//                 ...doc.data(),
//             }));
//             console.log(messages)
//             callback(messages);
//         }
//     );
// }


