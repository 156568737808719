import React, { useEffect, useState, useRef, useContext } from 'react'
import { Link, NavLink, useHistory, useLocation } from 'react-router-dom'
import Swal from 'sweetalert2'
import socket from '../Components/socket'
import '../css/landing.css'
import Rightcontainer from '../Components/Rightcontainer'
import Downloadbutton from '../Components/Downloadbutton'
import io from '../Components/socket'
import Header from '../Components/Header'
import { Token } from '@mui/icons-material'
import SliderCode from '../Components/SliderCode'
import { LanguageContext } from '../Components/LanguageContext'
import { useTheme } from '../../ThemeContext'

export default function Landing({ userAllData }) {
  const [open, setOpen] = useState(false)
  const udata = localStorage.getItem("udata");
  let profileData = JSON.parse(udata);
  // const [userAllData, setUserAllData] = useState()

  // language
  const translations = {
    hi: { Tournaments: 'प्रतियोगिता' },
    en: { Tournaments: 'Tournaments' },
  };
  const { language } = useContext(LanguageContext);

  const MyData = localStorage.getItem('meData')
  const meedata = JSON.parse(MyData)

  const addsetting = localStorage.getItem('sitSetting')
  const addCaseSetting = JSON.parse(addsetting)
  // console.log(addCaseSetting);

  // const role = async () => {
  //   const access_token = localStorage.getItem('token')
  //   io.emit('getprofile', access_token)
  //   io.on('setProfile', data => {
  //     // setUserAllData(data)
  //   })
  // }

  useEffect(() => {
    // role()
  }, [])

  // change theme 
  const theme = localStorage.getItem("theme")

  const styles = {
    backgroundColor: theme === 'light' ? '#fff' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
    // padding: '20px',
    // margin: '20px',
  };

  const styles3 = {
    backgroundColor: theme === 'light' ? '#facc06' : '#27241b',
    color: theme === 'light' ? 'black' : '#fff',
  };



  useEffect(() => {
    // console.log("ok");
    const UserId = localStorage.getItem("userId");
    socket.on("setProfile", (data) => {
      console.log(data, "dfghj")
      profileData = data

      // userId = data?._id;
    });
  }, [socket]);


  return (
    <>
      <div className='leftContainer mb_space' style={{ ...styles, height: "119% !important" }}>
        <div className='pt-3 Orher_page_main_section' style={styles}>
       
          <div className='main-area' style={styles}>
            {
              (JSON.parse(localStorage.getItem('sitSetting'))?.site_message != null &&
                JSON.parse(localStorage.getItem('sitSetting'))?.site_message != '') && (
                <div className='gameCard pt-2' style={styles}>
                  <div className='alrt_msg_div' style={styles3}>
                    <h5 className=''>
                      {JSON.parse(localStorage.getItem('sitSetting'))?.site_message}
                    </h5>
                  </div>
                </div>
              )
            }
               <SliderCode />



            <section className='games-section' style={styles}>
              {/* <h5 className='h5-bold'>PLAY GAMES WITH NKADDA</h5> */}

              <div className='games-section-headline mt-0 mb-1'>
                <div className='games-window '>


                  <>

                    {/* LUDO CLASSIC MANUAL */}

                    <Link
                      className='gameCard-container'
                      to={addCaseSetting?.isLudokingClassic ? '/Homepage/ludoClassicManual' : '#'}
                    >
                      <div className={`gameCard`}>
                        <span className='d-none blink  d-block text-right bg_green game_live_status_position'>

                          {addCaseSetting?.isLudokingClassic ? ' ◉ LIVE' : ' ◉ Comming Soon'}
                        </span>
                        <picture className='gameCard-image'>
                          <img
                            width='100%'
                            src='./mainimage/ludoclassic.jpg'
                            alt='Ludo Classic'
                          />
                        </picture>
                      </div>
                    </Link>


                    {
                      meedata?.isGameActive == true || !localStorage.getItem("token") ?
                        <>
                          {/* NKADDA CLASSIC */}
                          <Link
                            className='gameCard-container'
                            to={addCaseSetting?.NKADDAClassic ? '/Homepage/popular' : '#'}
                          >
                            <div className={`gameCard`}>
                              <span className='d-none blink  d-block text-right bg_green game_live_status_position'>

                                {addCaseSetting?.NKADDAClassic ? ' ◉ LIVE' : '  ◉ COMING SOON'}
                              </span>
                              <picture className='gameCard-image'>
                                <img
                                  width='100%'
                                  src='./mainimage/popular.jpg'
                                  alt='Ludo Classic'
                                />
                              </picture>
                            </div>
                          </Link>



                          {/* NKADDALUDO */}
                          <Link
                            className='gameCard-container'
                            to={addCaseSetting?.NKADDALudo ? '/Homepage/ludoulta1' : '#'}
                          >
                            <div className={`gameCard`}>
                              <span className='d-none blink  d-block text-right bg_green game_live_status_position'>

                                {addCaseSetting?.NKADDALudo ? ' ◉ LIVE' : '  ◉ COMING SOON'}
                              </span>
                              <picture className='gameCard-image'>
                                <img
                                  width='100%'
                                  src='./mainimage/ludoulta1.jpg'
                                  alt='Ludo Classic'
                                />
                              </picture>
                            </div>
                          </Link>
                          {/* NKADDAONE */}
                          <Link
                            className='gameCard-container'
                            to={addCaseSetting?.NKADDAOneGoti ? '/Homepage/nksupport1' : '#'}
                          >
                            <div className={`gameCard`}>
                              <span className='d-none blink  d-block text-right bg_green game_live_status_position'>

                                {addCaseSetting?.NKADDAOneGoti ? ' ◉ LIVE' : '  ◉ COMING SOON'}
                              </span>
                              <picture className='gameCard-image'>
                                <img
                                  width='100%'
                                  src='./mainimage/nksupport1.png'
                                  alt='Ludo Classic'
                                />
                              </picture>
                            </div>
                          </Link>


                        </> : ""
                    }
                  </>
                </div>
                <div>

                </div>
              </div>
            </section>

            {/* <div className='downloadButton'>
              <Downloadbutton />
            </div> */}
          </div>
        </div>
      </div>
      <div className="support_samll_card_btn_type whatsap_btn">
        {JSON.parse(localStorage.getItem("sitSetting")) ==
          "undefined" ? (
          ""
        ) : JSON.parse(localStorage.getItem("sitSetting"))
          ?.whatsappSupport ? (
          <>
            <a
              href={
                JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined"
                  ? ""
                  : JSON.parse(localStorage.getItem("sitSetting"))
                    ?.whatsappSupport
              }
              target="_blank"
            >
              <img
                width="40px"
                src={process.env.PUBLIC_URL + "/images/whatsapp.png"}
                alt=""
              />
            </a>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="support_samll_card_btn_type whatsap_btn" style={{ marginBottom: "65px" }}>
        {JSON.parse(localStorage.getItem("sitSetting")) ==
          "undefined" ? (
          ""
        ) : JSON.parse(localStorage.getItem("sitSetting"))
          ?.CompanyMobile ? (
          <>
            <a
              href={
                `tel:${JSON.parse(localStorage.getItem("sitSetting")) ==
                  "undefined"
                  ? ""
                  : JSON.parse(localStorage.getItem("sitSetting"))
                    ?.CompanyMobile}`
              }
              target="_blank"
            >
              <img
                width="40px"
                src={process.env.PUBLIC_URL + "/images/phone.png"}
                alt=""
              />
            </a>
          </>
        ) : (
          ""
        )}
      </div>
      <div className='rightContainer'>
        <Rightcontainer />
      </div>
    </>
  )
}
