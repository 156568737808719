import React, { useState, useEffect, useRef } from "react";
import css from "../css/gamehis.module.css";
import { Button } from 'react-bootstrap';

import Modal from "react-bootstrap/Modal";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { FaEye } from "react-icons/fa";
import Invoice from "./Invoice";
import { AiOutlineDownload } from "react-icons/ai";
import "./invoice.css";
import socket from "../Components/socket";
import { BsFillEyeFill } from "react-icons/bs";
import { apiRequest } from "../store/allApi";

const WithdrawHistory = () => {

  // BASE URL

  const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  const nodeMode = process.env.NODE_ENV;
  if (nodeMode === "development") {

    var baseUrl = beckendLocalApiUrl;
  } else {
    baseUrl = beckendLiveApiUrl;
  }
  // +++++++++++++++++++++++++++++ 
  let printRef = useRef(null);

  const [selectedCardId, setSelectedCardId] = useState(null);



  const [show, setShow] = useState(false);
  const [invoiceData, setInvoiceData] = useState("");
  const [isLoading, setIsLoading] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = (e) => {
    setShow(true);
    setInvoiceData(e);
  };

  const [user, setUser] = useState("");

  let limit = 50;
  const [pageNumber, setPageNumber] = useState(0);
  const [numberOfPages, setNumberOfPages] = useState(0);

  const role = async () => {
    socket.emit("getprofile");

    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };

    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      setUser(res.data);

      //Allgames(res.data._id)
      Allgames(res.data._id, pageNumber, limit);
      // window.location.reload()
    } catch (e) {
    }
  };

  const handlePageClick = async (data) => {
    let currentPage = data.selected + 1;
    setPageNumber(currentPage);

  };

  // ALL APIS HERE

  const [cardData, setGame] = useState();

  const Allgames = async (id) => {
    const access_token = localStorage.getItem("token");
    const headers = {
      Authorization: `Bearer ${access_token}`,
    };
    setIsLoading(true);

    try {
      const res = await apiRequest(`temp/deposite/${id}?page=${pageNumber}&_limit=${limit}`, "GET", null, headers)
      setGame(res.data.ress);
      {
        // console.log(res.data.ress);
      }
      setNumberOfPages(res.data.totalPages);
      setIsLoading(false);
    } catch (e) {
    }
  };



  useEffect(() => {
    console.log(cardData)
    role();
    //eslint-disable-next-line
  }, [pageNumber, limit]);

  const [Withdrawal, setWithdrawal] = useState();
  const [showBottom, setShowBottom] = useState(false);

  const handleCloseBottom = () => setShowBottom(false);
  const handleShowBottom = (data) => {
    setShowBottom(true);
    setWithdrawal(data);
  };

  // const dateFormat = (e) => {
  //   const date = new Date(e);
  //   const newDate = date.toLocaleString("default", {
  //     month: "short",
  //     day: "numeric",
  //     hour: "numeric",
  //     hour12: true,
  //     minute: "numeric",
  //   });
  //   return newDate;
  // };

  const dateFormat = (e) => {
    const date = new Date(e);
    const newDate = date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    });

    const time = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });

    return `${newDate}, ${time}`;
  };

  return (
    <>
      <div>



        {/* game-cards */}

        {isLoading ? (
          <div className="text-center">

            {cardData && cardData.length === 0 ? (
              <div className="mt-2">
                <h3 className="font-weight-bold">No transaction History</h3>
                <p className="text-muted">
                  You have not made any transaction yet.
                </p>
              </div>
            ) : (
              <div
                className="loaderReturn text-center"
                style={{ zIndex: "99" }}
              >
                <img
                  src={"/images/LandingPage_img/loader1.gif"}
                  style={{ width: "100%" }}
                />
              </div>
            )}
          </div>
        ) : (
          cardData &&
          cardData.length === 0 && (
            <div className="mt-2 text-center">
              <h3 className="font-weight-bold">No transaction History</h3>
              <p className="text-muted">
                You have not made any transaction yet.
              </p>
            </div>
          )
        )}

        {/* new code history  */}

        <div className="transaction_main_section_modal">
          {cardData &&
            cardData
              .filter((card) => card?.Req_type?.toLowerCase().trim() === "withdraw")
              .map((card) => {
                var id = card._id.toString(),
                  ctr = 0;
                var timestamp = id.slice(ctr, (ctr += 8));
                var machineID = id.slice(ctr, (ctr += 6));
                var processID = id.slice(ctr, (ctr += 4));
                var counter = id.slice(ctr, (ctr += 6));

                var counterid = parseInt(timestamp, 16);

                var titleMsg = "deposit"; // Default to "Penalty"
                if (
                  (card.Req_type === "deposit" || card.Req_type === "bonus") &&
                  card.status !== "FAILED"
                ) {
                  var titleMsg = "Cash added";
                  if (card.status === "Pending" && card.Req_type === "deposit") {
                    var signIcon = <div className="text-danger" style={{ fontSize: '16px' }}>(X)</div>;
                  } else {
                    var signIcon = <div className="text-success" style={{ fontSize: '16px' }}>(+)</div>;
                  }

                } else if (card.Req_type === "withdraw") {
                  var titleMsg = "Withdraw";
                  if (card.status === "REJECT") {
                    var signIcon = <div className="text-danger">(X)</div>;
                  } else if (card.status === "SUCCESS") {
                    var signIcon = <div className="text-danger">(-)</div>;
                  } else {
                    var signIcon = <div className="text-danger">(-)</div>;
                  }

                } else if (card.Req_type === "penalty" && card.status !== "FAILED") {
                  var titleMsg = "Penalty";
                  var signIcon = <div className="text-danger">(-)</div>;
                } else if (card.status === "Pending" || card.status === "FAILED") {
                  var titleMsg = "FAILED";
                  var signIcon = <div className="text-danger">(X)</div>;
                } else {
                  var titleMsg = "";
                  var signIcon = <div className="text-success"></div>;
                }


                return (
                  <>
                    <div
                      className="other_pages_card_main "
                      style={{ display: "flex", justifyContent: "space-between", alignItems: "center", background: "#f9f9f9", borderRadius: "12px", padding: "15px 8px", boxShadow: "0 4px 8px rgba(0,0,0,0.1)", }}
                    >
                      {/* Left Section */}

                      <div
                        className="card_date d-block border-right pr-3"
                        style={{ marginTop: "0px", textAlign: "center", fontSize: "14px", color: "", }}
                      >
                        {/* <p style={{ margin: "0" }}>{dateFormat(card.createdAt).split(",")[0]}</p>
                          <p style={{ margin: "0" }}>{dateFormat(card.createdAt).split(",")[1]}</p> */}
                        {dateFormat(card.createdAt).split(",")[0]} <br /> {dateFormat(card.createdAt).split(",")[1]}
                      </div>


                      <div className="ml-3" style={{ flex: 1 }}>
                        <div className="header-container">
                          <h3 className="title-msg">{titleMsg}</h3>
                          <span
                            className="ml-auto mr-3 eye"
                            onClick={() =>
                              setSelectedCardId(prevId => prevId === card._id ? null : card._id)
                            }
                          >
                            <FaEye />
                          </span>

                          {selectedCardId === card._id && (
                            <div className="popup-box">
                              {card.bankDetail?.upiId ? (
                                <p><strong>UPI ID:</strong> {card.bankDetail?.upiId}</p>
                              ) : (
                                <>
                                  <p><strong>Account Number:</strong> {card.bankDetail?.account_number}</p>
                                  <p><strong>IFSC Code:</strong> {card.bankDetail?.ifsc_code}</p>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                        {/* <h3
                          style={{ fontSize: "14px", fontWeight: "500", color: "#555", marginBottom: "10px", }}
                        >
                          Order ID:{" "}
                          <span style={{ color: "#7b7b7b", fontWeight: "400" }}>
                            {counterid}
                          </span>
                        </h3> */}


                        {/* Date Section */}
                        <p className=""
                          style={{ fontSize: "13px", color: "#333", marginBottom: "5px", }}
                        >
                          {" "}
                          <span
                            className="btn-bg "
                            style={{

                              background:
                                card.status === "REJECT"
                                  ? "red"
                                  : card.status === "SUCCESS"
                                    ? "green"
                                    : "blue",
                              color:
                                card.status === "Rejected" || card.status === "Success"
                                  ? "#fff" // White text for both
                                  : "#fff",
                            }}
                          >
                            {card.status === "none" ||
                              (card.status === "Pending" && card.Req_type === "withdraw")
                              ? "Processing"
                              : card.status === "Pending" && card.Req_type === "deposit"
                                ? "Cancelled"
                                : card.status}
                          </span>
                        </p>

                        {card.txn_msg && (
                          <span className="txn_masg">
                            <p>messages</p>
                            <p style={{ fontSize: "12px", color: "#F44336", marginTop: "5px" }}>
                              {card.txn_msg}
                            </p>
                          </span>
                        )}


                      </div>

                      {/* Right Section */}
                      <div
                        className="transaction_right_side_main"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-end",
                        }}
                      >
                        {/* Amount with Icon */}
                        <div
                          className="card_rupee d-flex"
                          style={{ display: "flex", fontSize: "16px", alignItems: "center", background: "#fff", padding: "6px 12px", borderRadius: "8px", boxShadow: "0 2px 5px rgba(0,0,0,0.1)", }}
                        >
                          {signIcon}
                          <picture>
                            <img
                              alt="img"
                              height="21px"
                              width="21px"
                              src={process.env.PUBLIC_URL + "/nkcoin.png"}
                              className="snip-img"
                              style={{ marginLeft: "5px" }}
                            />
                          </picture>
                          <span
                            style={{ paddingLeft: "5px", fontSize: "14px", fontWeight: "bold", color: "#333", }}
                          >
                            {card.amount}
                          </span>
                          {card.status === "SUCCESS" || card.status === "Pending" ? (
                            <img
                              style={{ width: '16px', marginLeft: '5px' }}
                              src={card.status === "SUCCESS" ? "/images/check.png" : "/images/pending.png"}
                              alt={card.status}
                            />
                          ) : null}

                        </div>

                        {/* Closing Balance */}
                        {card.closing_balance && (
                          <div className="gamehis_games_section_headline__BVYUG"
                            style={{ fontSize: "12px", marginTop: "8px", whiteSpace: "nowrap", color: "#757575", }}
                          >
                            Cl Bal : ₹ <strong>{card.closing_balance}</strong>
                          </div>
                        )}


                      </div>
                    </div>
                  </>
                );

              })}
        </div>
        <div>

        </div>
      </div>


    </>
  );
};

export default WithdrawHistory;
