import React, { useState, useEffect, useContext } from 'react'
import '../css/layout.css'
import '../css/homepage.css'
import css from '../css/with.css'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import '../css/Loader.css'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
// import findGif from "../css/loading.gif";
import findGif from '../css/loading_old.gif'
import io from '../Components/socket'
import Header from '../Components/Header'
import { LanguageContext } from '../Components/LanguageContext'
import { useTheme } from '../../ThemeContext'
import { apiRequest } from '../store/allApi'

const Withdrawopt = ({ walletUpdate }) => {
  // BASE URL
  // const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  // const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const nodeMode = process.env.NODE_ENV;


  // if (nodeMode === "development") {

  //   var baseUrl = beckendLocalApiUrl;
  // } else {
  //   baseUrl = beckendLiveApiUrl;
  // }
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  const udata = JSON.parse(localStorage.getItem("udata"));


  // +++++++++++++++++++++++++++++ 
  const history = useHistory()

  const access_token = localStorage.getItem('token')
  const [Id, setId] = useState(null)
  const [user, setUser] = useState()
  const [holder_name, setHolder_name] = useState()
  const [bank_name, setBank_name] = useState()
  const [account_number, setAccount_number] = useState()
  const [confirm_account_number, setConfirm_account_number] = useState()
  const [ifsc_code, setIfsc_code] = useState()
  const [upi_id, setUpi_id] = useState()
  const [confirm_upi_id, setConfirm_upi_id] = useState()
  const [next, setNext] = useState(0)
  const [paytmNumber, setpaytmNumber] = useState()
  const [withdrawAmount, setWithdrawAmount] = useState()
  const MyData = localStorage.getItem('meData')
  const meedata = JSON.parse(MyData)
  const [Qrwithdrawal, setQrWithdrawal] = useState(true);
  const [withdraw_Qr, setwithdraw_Qr] = useState(null)




  const [isCashFreePayoutActive, setCashFreePayoutActive] = useState(false)
  const [isRazorPayPayoutActive, setRazorPayPayoutActive] = useState(false)
  const [isDecentroPayoutActive, setDecentroPayoutActive] = useState(false)

  const [isRazorPayPayoutAuto, setRazorPayPayoutAuto] = useState(false)
  const [isDecentroPayoutAuto, setDecentroPayoutAuto] = useState(false)
  const [maxAutopayAmt, setMaxAutopayAmt] = useState(0)

  const [submitBtn, setSubmitBtn] = useState(true)
  const [withdrawal, setWithdrawal] = useState(true)
  const [Upiwithdrawal, setUpiWithdrawal] = useState(true)
  const [Bankwithdrawal, setBankWithdrawal] = useState(true)
  const [paytmwithdrawal, setpaytmWithdrawal] = useState(true)
  const [withdrawalLimitMinUpi, setWithdrawalUpiMin] = useState("");
  const [withdrawalLimitMaxUpi, setWithdrawalUpiMax] = useState("");
  const [withdrawalLimitMinBank, setWithdrawalBankMin] = useState("");
  const [withdrawalLimitMaxBank, setWithdrawalBankMax] = useState("");

  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)
  const [isLoading, setIsloading] = useState(false)

  const [type, setType] = useState(undefined)
  const [mount, setMount] = useState(false)

  const addsetting = localStorage.getItem('sitSetting')
  const addCaseSetting = JSON.parse(addsetting)
  useEffect(() => {
    setWithdrawal(addCaseSetting.isWithdrawal)
    setUpiWithdrawal(addCaseSetting.isUpiWithdrawal)
    setBankWithdrawal(addCaseSetting.isBankWithdrawal)
    setQrWithdrawal(addCaseSetting.isQrWithdrawal);
    setMinLimit(addCaseSetting.withdrawalLimitMin)
    setMaxLimit(addCaseSetting.withdrawalLimitMax)
    setWithdrawalUpiMin(addCaseSetting.withdrawalLimitMinUpi)
    setWithdrawalUpiMax(addCaseSetting.withdrawalLimitMaxUpi)
    setWithdrawalBankMin(addCaseSetting.withdrawalLimitMinBank)
    setWithdrawalBankMax(addCaseSetting.withdrawalLimitMaxBank)
  })

  const meapi = async () => {
    let access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      setUser(res.data)
      // console.log(res.data);
      setId(res.data._id)
      setHolder_name(res.data.holder_name)
      setBank_name(res.data.bank_name)
      setAccount_number(res.data.account_number)
      setConfirm_account_number(res.data.confirm_account_number)
      setIfsc_code(res.data.ifsc_code)
      setUpi_id(res.data.upi_id)
      setConfirm_upi_id(res.data.upi_id)
    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('token')
        window.location.reload()
        history.push('/login')
      }
    }
  }

  const websitesetting = async () => {
    let access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }
    try {
      const res = await apiRequest(`website/setting`, "GET", null, headers)

      setCashFreePayoutActive(res.data.isCashFreePayoutActive)
      setRazorPayPayoutActive(true)
      setDecentroPayoutActive(res.data.isDecentroPayoutActive)

      setRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto)
      setDecentroPayoutAuto(res.data.isDecentroPayoutAuto)
      setMaxAutopayAmt(res.data.maxAutopayAmt)
      setpaytmWithdrawal(res.data?.ispaytmWithdrawal)

    } catch (e) {
      setCashFreePayoutActive(false)
      setRazorPayPayoutActive(false)
      setDecentroPayoutActive(false)
      setMaxAutopayAmt(0)
    }
  }

  useEffect(() => {
    meapi()
    // websitesetting()
    // let access_token = localStorage.getItem('token')
    // const headers = {
    //   Authorization: `Bearer ${access_token}`
    // }

    // axios
    //   .get(baseUrl + `me`, { headers })
    //   .then(res => {
    //     setUser(res.data)
    //     // console.log(res.data);
    //     setId(res.data._id)
    //     setHolder_name(res.data.holder_name)
    //     setBank_name(res.data.bank_name)
    //     setAccount_number(res.data.account_number)
    //     setConfirm_account_number(res.data.confirm_account_number)
    //     setIfsc_code(res.data.ifsc_code)
    //     setUpi_id(res.data.upi_id)
    //     setConfirm_upi_id(res.data.upi_id)
    //   })
    //   .catch(e => {
    //     if (e.response.status == 401) {
    //       localStorage.removeItem('token')
    //       localStorage.removeItem('token')
    //       window.location.reload()
    //       history.push('/login')
    //     }
    //   })

    // axios
    //   .get(baseUrl + `website/setting`)
    //   .then(res => {
    //     //console.log(res);
    //     setCashFreePayoutActive(res.data.isCashFreePayoutActive)
    //     setRazorPayPayoutActive(true)
    //     setDecentroPayoutActive(res.data.isDecentroPayoutActive)

    //     setRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto)
    //     setDecentroPayoutAuto(res.data.isDecentroPayoutAuto)
    //     setMaxAutopayAmt(res.data.maxAutopayAmt)
    //     setpaytmWithdrawal(res.data?.ispaytmWithdrawal)

    //     // setUpiWithdrawal(addCaseSetting.isUpiWithdrawal)
    //     // setBankWithdrawal(addCaseSetting.isBankWithdrawal)
    //   })
    //   .catch(e => {
    //     setCashFreePayoutActive(false)
    //     setRazorPayPayoutActive(false)
    //     setDecentroPayoutActive(false)
    //     setMaxAutopayAmt(0)
    //   })
  }, [])

  const handleIfscChange = (e) => {
    const value = e.target.value.toUpperCase(); // Convert to uppercase
    if (value.length <= 11 && /^[A-Z0-9]*$/.test(value)) {
      // Allow only uppercase letters and digits, and limit to 11 characters
      setIfsc_code(value);
    }
  };

  const updateBankDetails = async () => {
    setIsloading(true)

    setMount(true)
    setSubmitBtn(false)
    // e.preventDefault();

    if (type === 'upi') {
      let regex = /^[\w.-]+@[\w.-]+$/.test(upi_id)

      if (regex) {
        console.log(upi_id == confirm_upi_id, upi_id, confirm_upi_id)
        if (upi_id == confirm_upi_id) {
          Swal.fire({
            title: `Is your UPI ID correct? ${upi_id}`,
            icon: 'success',
            confirmButtonText: 'OK'
          })

          const access_token = localStorage.getItem('token')
          const headers = {
            Authorization: `Bearer ${access_token}`
          }

          setIsloading(true)


          const data = await axios
            .patch(
              baseUrl + `/user/edit`,
              {
                holder_name,
                account_number,
                ifsc_code,
                upi_id,
                bankDetails: true
              },
              { headers }
            )
            .then(res => {
              setIsloading(false)
              // console.log('updata bank details', res)
              if (res.data.subCode === '200') {
                // console.log(res.data)
                let calculatedWallet =
                  user.wonAmount -
                  user.loseAmount +
                  user.totalDeposit +
                  user.referral_earning +
                  user.hold_balance +
                  user.totalBonus -
                  (user.totalWithdrawl +
                    user.referral_wallet +
                    user.totalPenalty)

                calculatedWallet == user.Wallet_balance
                  ? doAutoPayout()
                  : withReqComes()
              } else {
                setMount(false)
                setSubmitBtn(true)
                Swal.fire({
                  title: res.data.msg,
                  icon: 'error',
                  confirmButtonText: 'OK'
                })
              }
            })
            .catch(e => {
              console.log(e)
              setMount(false)
              if (e.response.status == 401) {
                localStorage.removeItem('token')
                localStorage.removeItem('token')
                window.location.reload()
                history.push('/login')
              }
            })
        } else {
          Swal.fire({
            title: 'UPI IDs do not match',
            text: 'Please make sure your UPI IDs match',
            icon: 'error',
            confirmButtonText: 'OK'
          })

          setSubmitBtn(true)
        }
      } else {
        Swal.fire({
          title: `Invalid UPI ID: ${upi_id}`,
          text: 'Please enter a valid UPI ID',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        setSubmitBtn(true)
      }
    }
    else if (type === 'banktransfer') {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      setIsloading(true)
      const data = await axios
        .patch(
          baseUrl + `/user/edit`,
          {
            holder_name,
            type,
            account_number,
            confirm_account_number,
            ifsc_code,
            upi_id,
            bankDetails: true
          },
          { headers }
        )
        .then(res => {
          setIsloading(false)
          // console.log('updata bank details', res)
          if (res.data.subCode === '200') {
            // console.log(res.data)
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referral_earning +
              user.hold_balance +
              user.totalBonus -
              (user.totalWithdrawl + user.referral_wallet + user.totalPenalty)

            calculatedWallet == user.Wallet_balance
              ? doAutoPayout()
              : withReqComes()
          } else {
            setMount(false)
            setSubmitBtn(true)
            Swal.fire({
              title: res.data.msg,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(e => {
          console.log(e)
          setMount(false)
          if (e.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location.reload()
            history.push('/login')
          }
        })
    }
    else if (type === "qrcode") {
      const access_token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${access_token}`,
      };

      setIsloading(true);
      const data = await axios
        .patch(
          baseUrl + `/user/edit`,
          {
            holder_name,
            type,
            account_number,
            confirm_account_number,
            ifsc_code,
            upi_id,
            bankDetails: true,
          },
          { headers }
        )
        .then((res) => {
          setIsloading(false);
          // console.log('updata bank details', res)
          if (res.data.subCode === "200") {
            // console.log(res.data)
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referral_earning +
              user.hold_balance +
              user.totalBonus -
              (user.totalWithdrawl + user.referral_wallet + user.totalPenalty);

            calculatedWallet == user.Wallet_balance
              ? doAutoPayout()
              : withReqComes();
          } else {
            setMount(false);
            setSubmitBtn(true);
            Swal.fire({
              title: res.data.msg,
              icon: "error",
              confirmButtonText: "OK",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          setMount(false);
          if (e.response.status == 401) {
            localStorage.removeItem("token");
            localStorage.removeItem("token");
            window.location.reload();
            history.push("/login");
          }
        });
    }
    else if (type === 'paytmransfer') {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }
      const data = await axios
        .patch(
          baseUrl + `/user/edit`,
          {
            holder_name,
            paytmNumber
          },
          { headers }
        )
        .then(res => {
          setIsloading(false)
          // console.log('updata bank details', res)
          if (res.data.subCode === '200') {
            // console.log(res.data)
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referral_earning +
              user.hold_balance +
              user.totalBonus -
              (user.totalWithdrawl + user.referral_wallet + user.totalPenalty)

            calculatedWallet == user.Wallet_balance
              ? doAutoPayout()
              : withReqComes()
          } else {
            setMount(false)
            setSubmitBtn(true)
            Swal.fire({
              title: res.data.msg,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(e => {
          console.log(e)
          setMount(false)
          if (e.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location.reload()
            history.push('/login')
          }
        })
    } else {
      setMount(false)
      setSubmitBtn(true)
    }
  }

  const [amount, setAmount] = useState()

  //this function for handleAuto payout service with payment gateway

  const doAutoPayout = () => {
    console.log(
      'type',
      type,
      isRazorPayPayoutAuto,
      isDecentroPayoutAuto,
      isCashFreePayoutActive
    )
    if (isRazorPayPayoutAuto && type == 'upi') {
      //alert('payoutFromRazorpay');
      if (amount <= maxAutopayAmt) {
        payoutFromRazorpay()
      } else {
        withReqComes()
      }
    } else if (isDecentroPayoutAuto && type == 'banktransfer') {
      //alert('payoutFromDecentro');
      if (amount <= maxAutopayAmt) {
        payoutFromDecentro()
      } else {
        withReqComes()
      }
    } else {
      withReqComes()
    }
  }

  // const payoutFromCashfree = () => {
  //   if (amount && amount >= 95 && amount <= 50000 && type) {
  //     // e.preventDefault();
  //     const payment_gatway = "cashfree";
  //     const access_token = localStorage.getItem("token");
  //     const headers = {
  //       Authorization: `Bearer ${access_token}`,
  //     };

  //     axios
  //       .post(
  //         baseUrl+`withdraw/payoutcashfreebank`,
  //         {
  //           amount,
  //           type,
  //           payment_gatway,
  //         },
  //         { headers }
  //       )
  //       .then((res) => {
  //         walletUpdate();
  //         setMount(false);
  //         console.log(res.data);
  //         if (res.data.subCode === "200") {
  //           console.log("cash res", res);
  //           Swal.fire({
  //             title: res.data.message,
  //             icon: "success",
  //             confirmButtonText: "OK",
  //           });
  //         } else {
  //           Swal.fire({
  //             title: res.data.message,
  //             icon: "danger",
  //             confirmButtonText: "OK",
  //           });
  //         }
  //       })
  //       .catch((e) => {
  //         setMount(false);
  //         Swal.fire({
  //           title: "Error! try after sometime.",
  //           icon: "error",
  //           confirmButtonText: "OK",
  //         });
  //         console.log(e);
  //       });
  //   } else {
  //     setMount(false);
  //     let msg = "Enter all fields";
  //     if (!amount || !type) {
  //       let msg = "Enter all fields";
  //     } else if (95 <= amount <= 50000) {
  //       msg = "amount should be more than 95 and less then 50000.";
  //     }
  //     Swal.fire({
  //       title: msg,
  //       icon: "Error",
  //       confirmButtonText: "OK",
  //     });
  //   }
  // };
  const payoutFromRazorpay = async () => {
    if (amount && amount >= 95 && amount <= 50000 && type) {
      // e.preventDefault();
      const payment_gatway = 'razorpay'
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/payoutrazorpaybank`, "POST", { amount, type, payment_gatway }, headers)
        walletUpdate()
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 50000) {
        msg = 'amount should be more than 95 and less then 50000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  //use for Razorpay payout end

  //use for decentro payout

  const payoutFromDecentro = async () => {
    if (amount && amount >= 95 && amount <= 50000 && type) {
      // e.preventDefault();
      const payment_gatway = 'decentro'
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/payoutdecentrobank`, "POST", { amount, type, payment_gatway }, headers)
        setTimeout(() => {
          walletUpdate()
        }, 5000)
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 50000) {
        msg = 'amount should be more than 95 and less then 50000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  //use for decentro payout end

  const handleSubmitdata = () => {
    if (amount && amount >= 95 && amount <= 20000 && type) {
      // e.preventDefault();
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = apiRequest(`withdraw/bank`, "POST", { amount, type }, headers)
        setTimeout(() => {
          walletUpdate()
        }, 5000)
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 20000) {
        msg = 'amount should be more than 95 and less then 100000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  const withReqComes = async () => {
    try {
      setMount(true)
      let payment_gateway = type === "upi" ? "razorpay" : "decentro";

      if (type == 'upi') {
        var payment_gatway = 'razorpay'
      } else {
        var payment_gatway = 'decentro'
      }

      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }



      if (amount < minLimit || amount > maxLimit) {
        Swal.fire({
          title: 'Invalid amount',
          text: 'Please enter an amount within the allowed limits.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } else {
        setMount(true)
        const formData = new FormData();

        // Append all your data fields to FormData
        formData.append('amount', amount);
        formData.append('type', type);
        formData.append('payment_gateway', payment_gateway);
        formData.append('withdraw_Qr', withdraw_Qr);
        formData.append('holder_name', holder_name);
        formData.append('account_number', account_number);
        formData.append('ifsc_code', ifsc_code);
        formData.append('upi_id', upi_id);
        formData.append('emergencyWithdraw', "false");

        try {
          const res = await apiRequest(`withdraw/request`, "POST", formData, headers)
          if (res.data.success) {
            setMount(false)

            Swal.fire({
              title: res?.data?.msg,
              icon: 'success',
              confirmButtonText: 'OK'
            })
            history.push('/')
          } else {
            Swal.fire({
              title: res.data.msg,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }

          setMount(false)
        } catch (e) {
          console.log(e)
          setMount(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  // language 
  const translations = {
    hi: {
      kyc: 'निकासी के लिए केवाईसी पूरा करें यहां क्लिक करें |',
    },
    en: {
      kyc: 'Complete KYC to take Withdrawals Click here',
    },
  };

  const { language } = useContext(LanguageContext);


  // change theme 
  const theme = localStorage.getItem('theme')


  const styles = {
    backgroundColor: theme === 'light' ? '#f5f5f5' : 'black',
    color: theme === 'light' ? 'black' : '#fff',
    // padding: '20px',
    // margin: '20px',
  };
  const styles3 = {
    backgroundColor: theme === 'light' ? '#facc06' : '#27241b',
    color: theme === 'light' ? 'black' : '#fff',
  };

  const handlePayment = () => {
    if (withdrawAmount) {
      setNext(2)
    } else {
      Swal.fire({
        title: 'Please Fill Amount',
        icon: 'error',
        confirmButtonText: 'OK'
      })
    }
  }

  return (
    <>
      <div
        className='leftContainer mb_space'
        style={{ ...styles, minHeight: '100vh', height: '100%' }}
      >

        <div className='container px-3 py-5' style={{ height: '10px' }}>
          <div className='row'>
            <div className='col mx-auto'>
              <div className=''>
                {user && user?.verified === 'verified' && user?.Email !== null && (
                  <div className=' '>
                    {
                      next != 3 && type != 'banktransfer' &&
                      <h6 className='pt-1 profile_headings ' style={styles}>
                        {type == undefined
                          ? 'Choose withdrawal option'
                          : 'Withdraw through'}
                      </h6>
                    }
                    {
                      next != 3 && type != 'banktransfer' &&
                      <>
                        {addCaseSetting?.isUpiWithdrawal == true || addCaseSetting?.isBankWithdrawal == true ?

                          <div className='gameCard pt-2' style={styles}>
                            <div className='alrt_msg_div' style={styles3}>
                              <h5 className=''>
                                {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                                  ? ''
                                  : JSON.parse(localStorage.getItem('sitSetting'))
                                    ?.withdraw_message}
                              </h5>
                            </div>
                          </div>
                          :
                          <div className='gameCard pt-2' style={styles}>
                            <div className='alrt_msg_div' style={styles3}>
                              <h5 className=''>
                                {JSON.parse(localStorage.getItem('sitSetting')) == 'undefined'
                                  ? ''
                                  : JSON.parse(localStorage.getItem('sitSetting'))
                                    ?.withdraw_messageOff}
                              </h5>
                            </div>
                          </div>
                        }
                      </>
                    }

                    {Boolean(!next) && (
                      <>
                        {addCaseSetting?.isUpiWithdrawal == true || addCaseSetting?.isBankWithdrawal == true ?

                          <div className="add_amount_main_box_inner">
                            <label className="label" style={styles}>Withdraw Amount</label>
                            <div className="enter_amount_input_box ">
                              <span className="img-size">
                                <img
                                  src="/images/nkcoin.png"
                                  alt=""
                                  className="img-fluid"
                                />
                              </span>
                              <input
                                className="enter_amount_input"
                                type="tel"
                                id="amountInput"
                                placeholder='Enter Amount To Withdraw'
                                onChange={(e) => setWithdrawAmount(e.target.value)}
                              />

                            </div>
                            <div className='text-center'>

                              <button type="button" class="btn2 btn-success" onClick={handlePayment}>Next</button>
                            </div>


                          </div>
                          : ""}
                      </>
                    )}


                    {next === 2 ? <div>
                      {/* upi id */}
                      {withdrawAmount >= withdrawalLimitMinUpi && Upiwithdrawal === true && withdrawAmount <= withdrawalLimitMaxUpi
                        ? (
                          <div
                            onClick={() => {
                              setType('upi')
                              setNext(3)
                            }}
                            className='add-fund-box my-3'
                            style={
                              {
                                // paddingTop: '0px',
                                // height: '60px'
                                //pointerEvents: "none", opacity: "0.6"
                              }
                            }
                          >
                            <div
                              className='d-flex align-items-center profile_info_small_box_main bank_page_cards mt-4'
                              style={{ minHeight: 'auto' }}
                            >
                              <div className='d-flex align-items-center'>
                                <img
                                  width='45px'
                                  src={process.env.PUBLIC_URL + '/UPI.png'}
                                  alt=''
                                  style={{
                                    marginLeft: '7px',
                                    paddingBottom: '10px',
                                    paddingLeft: '3px',
                                    paddingTop: '5px'
                                  }}
                                />
                              </div>
                              <div className='d-flex justify-content-center flex-column ml-4'>
                                <div className='text-left'>
                                  <h3 style={{ fontWeight: '500' }}>
                                    Withdraw through UPI
                                  </h3>
                                  <ul>
                                    <li>
                                      Minimum withdrawal amount {minLimit}
                                    </li>
                                    <li>
                                      Instant withdrawal within 30sec.
                                    </li>
                                  </ul>
                                </div>
                                <div className='jss31'></div>
                              </div>
                            </div>
                          </div>
                        )
                        : ''}
                      {/* bank account number */}
                      {withdrawAmount >= withdrawalLimitMinBank && Bankwithdrawal === true && withdrawAmount <= withdrawalLimitMaxBank
                        ? (
                          <div
                            onClick={() => {
                              setType('banktransfer')
                              setNext(4)
                            }}
                            className='add-fund-box my-3'
                          // style={{ paddingTop: '0px', height: '60px' }}
                          >
                            <div
                              className='d-flex align-items-center profile_info_small_box_main bank_page_cards'
                              style={{ minHeight: 'auto' }}
                            >
                              <div
                                className='d-flex align-items-center'
                              // style={{
                              //   height: '60px',
                              //   display: 'flex',
                              //   textAlign: 'center'
                              // }}
                              >
                                <img
                                  width='45px'
                                  src='../images/icons/bank.gif'
                                  alt=''
                                  style={{
                                    marginLeft: '7px',
                                    paddingBottom: '10px',
                                    paddingLeft: '3px',
                                    paddingTop: '5px'
                                  }}
                                />
                              </div>
                              <div className='d-flex justify-content-center flex-column ml-4'>
                                <div className='text-left'>
                                  <h3 style={{ fontWeight: '500' }}>
                                    Bank Transfer
                                  </h3>
                                  <ul>
                                    <li>
                                      Minimum withdrawal amount {minLimit}
                                    </li>
                                    <li>
                                      Instant withdrawal within 30sec.
                                    </li>
                                  </ul>
                                </div>
                                <div className='jss31'></div>
                              </div>
                            </div>
                          </div>
                        )
                        : ""}

                      {/* qr withdraw */}
                      {Qrwithdrawal === true ? (
                        <div
                          onClick={() => {
                            setType("qrcode");
                            setNext(true);
                          }}
                          className="add-fund-box my-3"
                        // style={{ paddingTop: '0px', height: '60px' }}
                        >
                          <div
                            className="d-flex align-items-center profile_info_small_box_main bank_page_cards"
                            style={{ minHeight: "auto" }}
                          >
                            <div
                              className="d-flex align-items-center"
                            // style={{
                            //   height: '60px',
                            //   display: 'flex',
                            //   textAlign: 'center'
                            // }}
                            >
                              <img
                                width="45px"
                                src="../images/icons/bank.gif"
                                alt=""
                                style={{
                                  marginLeft: "7px",
                                  paddingBottom: "10px",
                                  paddingLeft: "3px",
                                  paddingTop: "5px",
                                }}
                              />
                            </div>
                            <div className="d-flex justify-content-center flex-column ml-4">
                              <div className="text-left">
                                <h3 style={{ fontWeight: "500" }}>
                                  QR CODE
                                </h3>
                                <ul>
                                  <li>
                                    Minimum withdrawal amount {minLimit}
                                  </li>
                                  <li>
                                    Maximum withdrawal amount {maxLimit}
                                  </li>
                                  <li>
                                    Instant withdrawal within 30sec.
                                  </li>
                                </ul>
                              </div>
                              <div className="jss31"></div>
                            </div>
                          </div>
                        </div>
                      )
                        : ""}

                    </div>
                      :
                      ""
                    }


                    {Boolean(next) && (
                      <div>

                        <div className='row p-1'>
                          <div
                            className='text-left w-100'

                          >
                            <div className='mt-2'>
                              {/* bank account number */}

                              {Boolean(type == 'banktransfer') &&
                                type == 'banktransfer' && (
                                  <div>
                                    <button className='btn-withdrow' onClick={() => {
                                      setNext(false);
                                      setType(undefined);
                                      setWithdrawAmount('');
                                    }}>
                                      Choose another Option
                                    </button>

                                    <h3 style={{ fontSize: "16px" }} className='mb-4'>Enter your details carefully</h3>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                        style={{ color: '#000' }}
                                      >
                                        <i className='far fa-user mr-2'></i>
                                        Account holder name (खाता धारक का नाम)
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control bank_input'
                                          id='account_no'
                                          placeholder='Enter Account Name'
                                          name='ifsc'
                                          value={holder_name}
                                          onChange={e =>
                                            setHolder_name(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>

                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                        style={{ color: '#000' }}
                                      >
                                        <i className='far fa-bank mr-2'></i>
                                        Account number (खाता संख्या):
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='number'
                                          className='form-control bank_input'
                                          id='account_no'
                                          placeholder='Enter your bank account number'
                                          name='upi'
                                          required
                                          value={account_number}
                                          // onChange={e => setAccount_number(e.target.value) }
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,20}$/.test(value)) {
                                              setAccount_number(value);
                                            }
                                          }}
                                          disabled={!!udata?.account_number}
                                        />
                                      </div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                        style={{ color: '#000' }}
                                      >
                                        <i className='far fa-bank mr-2'></i>{' '}
                                        Confirm Account number (कन्फर्म खाता
                                        संख्या) :
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='number'
                                          className='form-control bank_input commaon_input_box'
                                          id='account_no'
                                          placeholder='Re Enter Your Bank Account Number'
                                          name='ifsc'
                                          // value={confirm_account_number}
                                          // onChange={e =>setConfirm_account_number(e.target.value) }
                                          value={confirm_account_number || udata?.account_number || ""}
                                          onChange={(e) => {
                                            const value = e.target.value;
                                            if (/^\d{0,20}$/.test(value)) {
                                              setConfirm_account_number(value);
                                            }
                                          }}
                                          disabled={!!udata?.account_number}

                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                        style={{ color: '#000' }}
                                      >
                                        <i className='far fa-bank mr-2'></i>
                                        IFSC code (IFSC कोड ) :
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control bank_input'
                                          id='account_no'
                                          placeholder='Enter IFSC code'
                                          name='ifsc'
                                          value={ifsc_code}
                                          // onChange={handleIfscChange}
                                          onChange={(e) => {
                                            const value = e.target.value.toUpperCase();
                                            if (/^[A-Z0-9]{0,11}$/.test(value)) {
                                              // Sirf A-Z aur 0-9 allow karega (Max 11)
                                              handleIfscChange(e);
                                            }
                                          }}
                                          style={{ textTransform: "uppercase" }}
                                          disabled={!!udata?.ifsc_code}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}


                              {/* upi id */}
                              {Boolean(type == 'upi') &&
                                type == 'upi' && next === 3 && (
                                  <div className='mt-2'>

                                    <button className='btn-withdrow' onClick={() => {
                                      setNext(false);
                                      setType(undefined);
                                      setWithdrawAmount('');
                                    }}>
                                      Choose another Option
                                    </button>

                                    <h3 style={{ fontSize: "16px" }} className='mb-4'>Enter your details carefully</h3>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                        style={{ color: '#000' }}
                                      >
                                        <i className='far fa-user mr-2'></i>
                                        Account holder name (खाता धारक का नाम) :
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className=' form-control bank_input'
                                          id='account_no'
                                          placeholder='Enter Account Name'
                                          name='ifsc'
                                          value={holder_name}
                                          onChange={e =>
                                            setHolder_name(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>

                                    <label
                                      htmlFor='username '
                                      className='mr-5 commaon_label'
                                      style={{ color: '#000' }}
                                    >
                                      <i className='far fa-bank mr-2'></i>UPI ID
                                      (UPI आईडी) || EX. (9999999999@xyz)
                                    </label>
                                    <div className='col-12 mb-3 p-0'>
                                      <input
                                        type='text'
                                        className='form-control bank_input'
                                        id='account_no'
                                        placeholder='Enter Your UPI ID Ex.(9999999999@xyz)'
                                        name='ifsc'
                                        value={upi_id}
                                        // onChange={e => setUpi_id(e.target.value)}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (/^[a-zA-Z0-9@._]{0,50}$/.test(value)) { // Allow letters, numbers, @, ., _
                                            setUpi_id(value);
                                          }
                                        }}
                                        disabled={!!udata?.upi_id}
                                      />
                                    </div>

                                    <label
                                      htmlFor='username '
                                      className=' commaon_label'
                                      style={{ color: '#000' }}
                                    >
                                      <i className='far fa-bank mr-2'></i>{' '}
                                      Confirm UPI ID: (कन्फर्म UPI आईडी) || EX.
                                      (9999999999@xyz)
                                    </label>
                                    <div className='col-12 mb-3 p-0'>
                                      <input
                                        type='text'
                                        className='form-control bank_input'
                                        id='account_no'
                                        placeholder='Re Enter Your UPI ID Ex.(9999999999@xyz)'
                                        name='ifsc'
                                        // value={confirm_upi_id}
                                        // onChange={e => setConfirm_upi_id(e.target.value) }

                                        value={confirm_upi_id || udata?.upi_id || ""}
                                        onChange={(e) => {
                                          const value = e.target.value;
                                          if (/^[a-zA-Z0-9@._]{0,50}$/.test(value)) {
                                            setConfirm_upi_id(value);
                                          }
                                        }}
                                        disabled={!!udata?.upi_id}
                                      />
                                    </div>
                                    <small className=' small_para'>
                                      कृपया सही UPI आईडी Ex.(9999999999@xyz)
                                      दर्ज करें।*
                                    </small>
                                  </div>
                                )}

                              {type === "qrcode" && (
                                <div>
                                  <div>
                                    <label
                                      htmlFor="username "
                                      className="mr-5 commaon_label"
                                      style={{ color: "green" }}
                                    >
                                      <i className="far fa-user mr-2"></i>
                                      Account holder name (खाता धारक का नाम)
                                    </label>
                                    <div className="col-12 mb-3 p-0">
                                      <input
                                        type="text"
                                        className="form-control bank_input"
                                        id="account_no"
                                        placeholder="Enter Account Name"
                                        name="ifsc"
                                        value={holder_name}
                                        onChange={(e) =>
                                          setHolder_name(e.target.value)
                                        }
                                        required
                                      />
                                    </div>
                                    <label
                                      htmlFor="username "
                                      className="mr-5 commaon_label"
                                      style={{ color: "green" }}
                                    >
                                      <i className="far fa-user mr-2"></i>
                                      Upload Your Qr Code (अपना क्यूआर कोड डाले |)
                                    </label>
                                    <div className="col-12 mb-3 p-0">
                                      <Row
                                        style={{ marginRight: '4.25rem', marginTop: '10px' }}
                                      >
                                        <Col sm={1}>
                                          <input
                                            type='file'
                                            accept='image/*'
                                            name='rkludoPopular'
                                            onChange={e =>
                                              setwithdraw_Qr(e.target.files[0])
                                            }
                                            style={{ width: '200px' }}
                                          />
                                        </Col>
                                      </Row>
                                    </div>
                                  </div>

                                </div>
                              )}

                            </div>
                          </div>

                          {/* bank details end */}


                          {next === 4 || next === 3 || type === "qrcode" ?
                            <>
                              <div>
                                <label
                                  htmlFor='username '
                                  className='mr-5 commaon_label'
                                  style={{ color: '#000' }}
                                >
                                  <img
                                    src={
                                      '/nkcoin.png'
                                    }
                                    alt=''
                                    className='img-fluid'
                                    style={{ maxWidth: '20px' }}
                                  />{' '}
                                  Amount
                                </label>
                              </div>
                              <div className='field col-12 p-0 mt-1 mb-3'>
                                <input
                                  type='phone'
                                  className='form-control  search-slt commaon_input_box'
                                  name='amount'
                                  placeholder='Enter amount'
                                  // onChange={(e) => setAmount(e.target.value)}
                                  onChange={e => {
                                    const value = e.target.value
                                    e.target.value = value.replace(/[^\d]/g, '')
                                    setAmount(e.target.value)
                                  }}
                                />
                              </div>
                              <div
                                className='col-12 p-0 mt-2 pt-3'
                                style={{ marginBottom: '100px' }}
                              >
                                {isLoading ? (
                                  <div className='pl-2 text-center'>
                                    <img
                                      src={findGif}
                                      style={{ width: '80px', height: '80px' }}
                                    />
                                  </div>
                                ) : (
                                  <button
                                    type='button'
                                    className=' btn-block btn-sm '
                                    id='withdrawcase'
                                    style={{
                                      height: '40px',
                                      backgroundColor: '#23233d',
                                      color: 'white',
                                      borderRadius: '21px'
                                    }}
                                    disabled={Boolean(submitBtn) ? false : true}
                                    onClick={() => {
                                      if ((meedata.holdBalance_by_admin === 0) || (amount <= (meedata.Wallet_balance - meedata?.holdBalance_by_admin))) {
                                        updateBankDetails()
                                      } else {
                                        Swal.fire({
                                          title: `${meedata?.holdBalance_by_admin} amount hold by admin please contact to admin`,
                                          icon: "warning",
                                          confirmButtonText: "OK",
                                        });
                                      }


                                    }}
                                  >
                                    WITHDRWAL SUBMIT
                                  </button>
                                )}

                                {/* {Boolean(submitBtn) ? "" : "Reload Page"}s */}
                              </div>
                            </>
                            : ""}
                        </div>

                      </div>
                    )}
                  </div>
                )}

                {user && user?.verified === 'unverified' && (
                  <Link to='/profile'>
                    <div style={{ height: '100px' }} className='kyc_page_cards'>
                      <picture className='ml-3'>
                        <img
                          src='/images/alert.svg'
                          alt=''
                          width='32px'
                          className=''
                        />
                      </picture>
                      <p className='ml-1  mytext '>
                        {translations[language].kyc}
                      </p>
                    </div>
                  </Link>
                )}
                {user && user.verified === 'reject' && (
                  <div style={{ height: '100px' }} className='kyc_page_cards'>
                    <picture className='ml-3'>
                      <img
                        src='/images/alert.svg'
                        alt=''
                        width='32px'
                        className=''
                      />
                    </picture>
                    <p className='ml-1  mytext '>
                      Your kyc was rejected please please try again !
                    </p>
                  </div>
                )}
                {user && user.verified === 'pending' && (
                  <div style={{ height: '100px' }} className='kyc_page_cards'>
                    <picture className='ml-3'>
                      <img
                        src='/images/alert.svg'
                        alt=''
                        width='32px'
                        className=''
                      />
                    </picture>
                    <p className='ml-1  mytext  '>
                      Please wait your kyc under process
                    </p>
                  </div>
                )}
                {user && user?.Email === null && (
                  <div style={{ height: '100px' }} className='kyc_page_cards'>
                    <picture className='ml-3'>
                      <img
                        src='/images/alert.svg'
                        alt=''
                        width='32px'
                        className=''
                      />
                    </picture>
                    <p className='ml-1  mytext '>
                      Complete Email Verification to take Withdrawals
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Withdrawopt
