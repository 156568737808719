import React, { useState, useEffect } from 'react'
import '../css/layout.css'
import '../css/homepage.css'
import css from '../css/with.css'
import axios from 'axios'
import { Link, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import '../css/Loader.css'
import findGif from '../css/loading.gif'
import { apiRequest } from '../store/allApi'
import { hexToRgb } from '@mui/material'

const Withdrawopt = ({ walletUpdate }) => {
  const history = useHistory()
  // BASE URL

  // const beckendLocalApiUrl = process.env.REACT_APP_BACKEND_LOCAL_API;
  // const beckendLiveApiUrl = process.env.REACT_APP_BACKEND_LIVE_API;
  // const nodeMode = process.env.NODE_ENV;
  // if (nodeMode === "development") {

  //   var baseUrl = beckendLocalApiUrl;
  // } else {
  //   baseUrl = beckendLiveApiUrl;
  // }
  const baseUrl = process.env.REACT_APP_API_BASE_URL;
  // +++++++++++++++++++++++++++++ 
  const access_token = localStorage.getItem('token')
  const [Id, setId] = useState(null)
  const [user, setUser] = useState()
  const [holder_name, setHolder_name] = useState()
  const [account_number, setAccount_number] = useState()
  const [ifsc_code, setIfsc_code] = useState()
  const [upi_id, setUpi_id] = useState()
  const [confirm_upi_id, setConfirm_upi_id] = useState()
  const [next, setNext] = useState(false)

  const [isCashFreePayoutActive, setCashFreePayoutActive] = useState(false)
  const [isRazorPayPayoutActive, setRazorPayPayoutActive] = useState(false)
  const [isDecentroPayoutActive, setDecentroPayoutActive] = useState(false)

  const [isRazorPayPayoutAuto, setRazorPayPayoutAuto] = useState(false)
  const [isDecentroPayoutAuto, setDecentroPayoutAuto] = useState(false)
  const [maxAutopayAmt, setMaxAutopayAmt] = useState(0)

  const [submitBtn, setSubmitBtn] = useState(true)
  const [withdrawal, setWithdrawal] = useState(true)
  const [minLimit, setMinLimit] = useState(null)
  const [maxLimit, setMaxLimit] = useState(null)

  const [type, setType] = useState(undefined)
  const [mount, setMount] = useState(false)

  useEffect(() => {
    const addsetting = localStorage.getItem('sitSetting')
    const addCaseSetting = JSON.parse(addsetting)
    setWithdrawal(addCaseSetting.isWithdrawal)

    setMinLimit(addCaseSetting.withdrawalLimitMin)
    setMaxLimit(addCaseSetting.withdrawalLimitMax)
  })

  useEffect(() => {
    meapi();
    websitesetings();
  }, [])

  const meapi = async () => {
    let access_token = localStorage.getItem('token')
    const headers = {
      Authorization: `Bearer ${access_token}`
    }

    try {
      const res = await apiRequest(`me`, "GET", null, headers)
      setUser(res.data)
      setId(res.data._id)
      setHolder_name(res.data.holder_name)
      setAccount_number(res.data.account_number)
      setIfsc_code(res.data.ifsc_code)
      setUpi_id(res.data.upi_id)
      setConfirm_upi_id(res.data.confirm_upi_id)

    } catch (e) {
      if (e.response.status == 401) {
        localStorage.removeItem('token')
        localStorage.removeItem('token')
        window.location.reload()
        history.push('/login')
      }
    }
  }

  const websitesetings = async () => {
    try {
      const res = await apiRequest(`website/setting`, "GET", null)

      //console.log(res);
      setCashFreePayoutActive(res.data.isCashFreePayoutActive)
      setRazorPayPayoutActive(true)
      setDecentroPayoutActive(res.data.isDecentroPayoutActive)

      setRazorPayPayoutAuto(res.data.isRazorPayPayoutAuto)
      setDecentroPayoutAuto(res.data.isDecentroPayoutAuto)
      setMaxAutopayAmt(res.data.maxAutopayAmt)
    }
    catch (e) {
      setCashFreePayoutActive(false)
      setRazorPayPayoutActive(false)
      setDecentroPayoutActive(false)
      setMaxAutopayAmt(0)
    }
  }


  const updateBankDetails = async () => {
    // setMount(true);
    setSubmitBtn(false)
    // e.preventDefault();
    let confirm = false

    if (type === 'upi') {
      let regex = /^[\w.-]+@[\w.-]+$/.test(upi_id)

      if (regex) {
        if (upi_id === confirm_upi_id) {
          Swal.fire({
            title: `Is your UPI ID correct? ${upi_id}`,
            icon: 'success',
            confirmButtonText: 'OK'
          })
          confirm = true
        } else {
          Swal.fire({
            title: 'UPI IDs do not match',
            text: 'Please make sure your UPI IDs match',
            icon: 'error',
            confirmButtonText: 'OK'
          })
          confirm = false
          setSubmitBtn(true)
        }
      } else {
        Swal.fire({
          title: `Invalid UPI ID: ${upi_id}`,
          text: 'Please enter a valid UPI ID',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        confirm = false
        setSubmitBtn(true)
      }
    } else {
      if (!holder_name || !account_number || !ifsc_code) {
        setMount(false)
        Swal.fire({
          title: 'Invalid Bank Details',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        confirm = false
        setSubmitBtn(true)
      } else {
        confirm = true
      }
    }
    //const confirm2 = window.confirm('Are your sure your account details are correct ?')

    if (confirm) {
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      const data = await axios
        .patch(
          baseUrl + `user/edit`,
          {
            holder_name,
            account_number,
            ifsc_code,
            upi_id,
            bankDetails: true
          },
          { headers }
        )
        .then(res => {
          console.log('updata bank details', res)
          if (res.data.subCode === '200') {
            let calculatedWallet =
              user.wonAmount -
              user.loseAmount +
              user.totalDeposit +
              user.referral_earning +
              user.hold_balance +
              user.totalBonus -
              (user.totalWithdrawl + user.referral_wallet + user.totalPenalty)

            calculatedWallet == user.Wallet_balance
              ? doAutoPayout()
              : withReqComes()
          } else {
            setMount(false)
            setSubmitBtn(true)
            Swal.fire({
              title: res.data.message,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
        })
        .catch(e => {
          console.log(e)
          setMount(false)
          if (e.response.status == 401) {
            localStorage.removeItem('token')
            localStorage.removeItem('token')
            window.location.reload()
            history.push('/login')
          }
        })
    } else {
      setMount(false)
      setSubmitBtn(true)
    }
  }

  const [amount, setAmount] = useState()
  // console.log(amount)

  // const [accunt, setUpi] = useState()
  // const [account_no, setAccount_no] = useState()
  // const [IFSC, setIFSC] = useState();

  //this function for handleAuto payout service with payment gateway

  const doAutoPayout = () => {
    console.log(
      'type',
      type,
      isRazorPayPayoutAuto,
      isDecentroPayoutAuto,
      isCashFreePayoutActive
    )
    if (isRazorPayPayoutAuto && type == 'upi') {
      //alert('payoutFromRazorpay');
      if (amount <= maxAutopayAmt) {
        payoutFromRazorpay()
      } else {
        withReqComes()
      }
    } else if (isDecentroPayoutAuto && type == 'banktransfer') {
      //alert('payoutFromDecentro');
      if (amount <= maxAutopayAmt) {
        payoutFromDecentro()
      } else {
        withReqComes()
      }
    }
    // else if(isCashFreePayoutActive && type=='banktransfer'){
    //   //alert('payoutFrisDecentroPayoutAutoomDecentro');
    //   alert(maxAutopayAmt)
    //   if(amount <= maxAutopayAmt){
    //     payoutFromCashfree();
    //   }else{
    //     withReqComes();
    //   }
    // }
    else {
      //alert('withReqComes');
      withReqComes()
    }
  }

  //use for Razorpay payout

  const payoutFromCashfree = async () => {
    if (amount && amount >= 95 && amount <= 50000 && type) {
      // e.preventDefault();
      const payment_gatway = 'cashfree'
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/payoutcashfreebank`, "POST", { amount, type, payment_gatway }, headers)
        walletUpdate()
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 50000) {
        msg = 'amount should be more than 95 and less then 50000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }
  const payoutFromRazorpay = async () => {
    if (amount && amount >= 95 && amount <= 50000 && type) {
      // e.preventDefault();
      const payment_gatway = 'razorpay'
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/payoutrazorpaybank`, "POST", { amount, type, payment_gatway }, headers)
        walletUpdate()
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 50000) {
        msg = 'amount should be more than 95 and less then 50000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  //use for Razorpay payout end

  //use for decentro payout

  const payoutFromDecentro = async () => {
    if (amount && amount >= 95 && amount <= 50000 && type) {
      // e.preventDefault();
      const payment_gatway = 'decentro'
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/payoutdecentrobank`,"POST",{amount,type,payment_gatway} , headers)
        setTimeout(() => {
          walletUpdate()
        }, 5000)
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 50000) {
        msg = 'amount should be more than 95 and less then 50000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  //use for decentro payout end

  const handleSubmitdata = async () => {
    if (amount && amount >= 95 && amount <= 20000 && type) {
      // e.preventDefault();
      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      try {
        const res = await apiRequest(`withdraw/bank`,"POST",{amount,type},headers)
        setTimeout(() => {
          walletUpdate()
        }, 5000)
        setMount(false)
        console.log(res.data)
        if (res.data.subCode === '200') {
          console.log('cash res', res)
          Swal.fire({
            title: res.data.message,
            icon: 'success',
            confirmButtonText: 'OK'
          })
        } else {
          Swal.fire({
            title: res.data.message,
            icon: 'danger',
            confirmButtonText: 'OK'
          })
        }
      } catch (e) {
        setMount(false)
        Swal.fire({
          title: 'Error! try after sometime.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
        console.log(e)
      }
    } else {
      setMount(false)
      let msg = 'Enter all fields'
      if (!amount || !type) {
        let msg = 'Enter all fields'
      } else if (95 <= amount <= 20000) {
        msg = 'amount should be more than 95 and less then 100000.'
      }
      Swal.fire({
        title: msg,
        icon: 'Error',
        confirmButtonText: 'OK'
      })
    }
  }

  const withReqComes = async () => {
    try {
      setMount(true)

      if (type == 'upi') {
        var payment_gatway = 'razorpay'
      } else {
        var payment_gatway = 'decentro'
      }

      const access_token = localStorage.getItem('token')
      const headers = {
        Authorization: `Bearer ${access_token}`
      }

      if (amount < minLimit || amount > maxLimit) {
        Swal.fire({
          title: 'Invalid amount',
          text: 'Please enter an amount within the allowed limits.',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      } else {
        setMount(true)

        try {
          const res = await apiRequest(`withdraw/request`,"POST",{amount,type,payment_gatway},headers)
          walletUpdate()
          if (res.data.success) {
            setMount(false)
            Swal.fire({
              title: res.data.msg,
              icon: 'success',
              confirmButtonText: 'OK'
            })
          } else {
            Swal.fire({
              title: res.data.msg,
              icon: 'error',
              confirmButtonText: 'OK'
            })
          }
          setMount(false)
        } catch (e) {
          console.log(e)
          setMount(false)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <div
        className='leftContainer mb_space'
        style={{ minHeight: '100vh', height: '100%' }}
      >
        {/* {Boolean(mount) && (
          <div
            className="loaderReturn"
            style={{ minHeight: "100vh", height: "100%" }}
          >
            <img src={findGif} style={{ width: "100%", height: "486px" }} />
          </div>
        )} */}
        <div className='container px-3 mt-5 py-5' style={{ height: '10px' }}>
          <div className='row'>
            <div className='col mx-auto'>
              <div className='mt-3'>
                {user && user.verified === 'verified' && (
                  <div className=' '>
                    <h4 className='pt-1 profile_headings mb-4' >
                      {type == undefined
                        ? 'Choose withdrawal option.'
                        : 'Withdraw through'}
                    </h4>
                    {Boolean(!next) && (
                      <div>
                        {Boolean(isRazorPayPayoutActive) && (
                          <div
                            onClick={() => {
                              setType('upi')
                              setNext(true)
                            }}
                            className='add-fund-box my-3'
                            style={
                              {
                                // paddingTop: '0px',
                                // height: '60px'
                                //pointerEvents: "none", opacity: "0.6"
                              }
                            }
                          >
                            <div
                              className='d-flex align-items-center profile_info_small_box_main'
                              style={{ minHeight: 'auto' }}
                            >
                              <div
                                className='d-flex align-items-center'
                              // style={{
                              //   height: '60px',
                              //   display: 'flex',
                              //   textAlign: 'center'
                              // }}
                              >
                                <img
                                  width='45px'
                                  src={process.env.PUBLIC_URL + '/UPI.png'}
                                  alt=''
                                  style={{
                                    marginLeft: '7px',
                                    paddingBottom: '10px',
                                    paddingLeft: '3px',
                                    paddingTop: '5px'
                                  }}
                                />
                              </div>
                              <div className='d-flex justify-content-center flex-column ml-4'>
                                <div className='text-left'>
                                  <h3 style={{ fontWeight: '500' }}>
                                    Withdraw through UPI
                                  </h3>
                                  <h3
                                    className='text-warning'
                                    style={{
                                      fontWeight: '400',
                                      fontSize: '0.8rem'
                                    }}
                                  >
                                    Minimum withdrawal amount {minLimit}
                                  </h3>
                                  <h3
                                    className='text-danger'
                                    style={{
                                      fontWeight: '400',
                                      fontSize: '0.8rem'
                                    }}
                                  >
                                    Instant withdrawal within 30sec.
                                  </h3>
                                </div>
                                <div className='jss31'></div>
                              </div>
                            </div>
                          </div>
                        )}
                        {Boolean(isRazorPayPayoutActive) && (
                          <div
                            onClick={() => {
                              setType('banktransfer')
                              setNext(true)
                            }}
                            className='add-fund-box my-3'
                            style={{
                              paddingTop: '0px',
                              height: '60px'
                              //pointerEvents: "none", opacity: "0.6"
                            }}
                          >
                            <div
                              className='d-flex align-items-center'
                              style={{
                                backgroundColor: '#fafafa',
                                border: '1px solid grey',
                                borderRadius: '7px'
                              }}
                            >
                              <div
                                className='d-flex align-items-center'
                                style={{
                                  height: '60px',
                                  display: 'flex',
                                  textAlign: 'center'
                                }}
                              >
                                <img
                                  width='45px'
                                  src={process.env.PUBLIC_URL + '/UPI.png'}
                                  alt=''
                                  style={{
                                    marginLeft: '7px',
                                    paddingBottom: '10px',
                                    paddingLeft: '3px',
                                    paddingTop: '5px'
                                  }}
                                />
                              </div>
                              <div className='d-flex justify-content-center flex-column ml-4'>
                                <div className='text-left'>
                                  <strong>Withdraw direct on account</strong>
                                  <br />
                                  <small className='text-warning'>
                                    Minimum withdrawal amount {minLimit}
                                  </small>
                                  <br />
                                  <small className='text-danger'>
                                    Instant withdrawal within 30sec.
                                  </small>
                                </div>
                                <div className='jss31'></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {Boolean(next) && (
                      <div>
                        <div className='row bg-light p-1'>
                          <div
                            className='text-left w-100'
                            style={{ background: 'white' }}
                          >
                            {Boolean(
                              isRazorPayPayoutActive || isCashFreePayoutActive
                            ) &&
                              Boolean(type == 'upi') && (
                                <div
                                  className='add-fund-box my-3'
                                // style={{ paddingTop: '0px', height: '60px' }}
                                >
                                  <div
                                    className='d-flex align-items-center profile_info_small_box_main'
                                    style={{ minHeight: 'auto' }}

                                  // style={{
                                  //   backgroundColor: '#fafafa',
                                  //   border: '1px solid grey',
                                  //   borderRadius: '7px'
                                  // }}
                                  >
                                    <div
                                      className='d-flex align-items-center '
                                    // style={{
                                    //   height: '60px',
                                    //   display: 'flex',
                                    //   textAlign: 'center'
                                    // }}
                                    >
                                      <img
                                        width='45px'
                                        src={
                                          process.env.PUBLIC_URL + '/UPI.png'
                                        }
                                        alt=''
                                        style={{
                                          marginLeft: '7px',
                                          paddingBottom: '10px',
                                          paddingLeft: '3px',
                                          paddingTop: '5px'
                                        }}
                                      />
                                    </div>
                                    <div className='d-flex justify-content-center flex-column ml-4'>
                                      <div className='text-left'>
                                        <h3 style={{ fontWeight: '500' }}>
                                          Withdraw through UPI
                                        </h3>

                                        <h3
                                          className='text-warning'
                                          style={{
                                            fontWeight: '400',
                                            fontSize: '0.8rem'
                                          }}
                                        >
                                          Minimum withdrawal amount {minLimit}
                                        </h3>

                                        <h3
                                          className='text-danger'
                                          style={{
                                            fontWeight: '400',
                                            fontSize: '0.8rem'
                                          }}
                                        >
                                          Instant withdrawal within 30sec.
                                        </h3>
                                      </div>
                                      <div className='jss31'></div>
                                    </div>
                                    <button
                                      onClick={() => {
                                        setNext(false)
                                      }}
                                      className='btn btn-info text-white font-weight-bold ml-auto mr-3 '
                                      style={{ fontSize: '0.5rem' }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              )}
                            {Boolean(
                              isRazorPayPayoutActive || isCashFreePayoutActive
                            ) &&
                              Boolean(type == 'banktransfer') && (
                                <div
                                  className='add-fund-box my-3'
                                  style={{ paddingTop: '0px', height: '60px' }}
                                >
                                  <div
                                    className='d-flex align-items-center'
                                    style={{
                                      backgroundColor: '#fafafa',
                                      border: '1px solid grey',
                                      borderRadius: '7px'
                                    }}
                                  >
                                    <div
                                      className='d-flex align-items-center'
                                      style={{
                                        height: '60px',
                                        display: 'flex',
                                        textAlign: 'center'
                                      }}
                                    >
                                      <img
                                        width='45px'
                                        src={
                                          process.env.PUBLIC_URL + '/Bank.png'
                                        }
                                        alt=''
                                        style={{
                                          marginLeft: '7px',
                                          paddingBottom: '10px',
                                          paddingLeft: '3px',
                                          paddingTop: '5px'
                                        }}
                                      />
                                    </div>
                                    <div className='d-flex justify-content-center flex-column ml-4'>
                                      <div className='text-left'>
                                        <strong>Bank Transfer</strong>
                                        <br />
                                        <small className='text-warning'>
                                          Minimum withdrawal amount {minLimit}
                                        </small>
                                        <br />
                                        <small className='text-danger'>
                                          Direct Bank Transaction will take 1-2
                                          hour
                                        </small>
                                      </div>
                                      <div className='jss31'></div>
                                    </div>
                                    <button
                                      onClick={() => {
                                        setType(undefined)
                                        setNext(false)
                                        console.log(type)
                                      }}
                                      className='btn btn-info text-white font-weight-bold ml-auto mr-3'
                                      style={{ fontSize: '0.5rem' }}
                                    >
                                      Edit
                                    </button>
                                  </div>
                                </div>
                              )}
                            <div className=''>
                              {Boolean(
                                isRazorPayPayoutActive
                              ) &&
                                type == 'banktransfer' && (
                                  <div>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5'
                                      >
                                        <i className='far fa-bank mr-2'></i>
                                        Account holder name
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          id='account_no'
                                          placeholder='Enter Account Name'
                                          name='ifsc'
                                          value={holder_name}
                                          onChange={e =>
                                            setHolder_name(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5'
                                      >
                                        <i className='far fa-bank mr-2'></i>
                                        Account number
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          id='account_no'
                                          placeholder='Enter your bank account number'
                                          name='upi'
                                          value={account_number}
                                          onChange={e =>
                                            setAccount_number(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5'
                                      >
                                        <i className='far fa-bank mr-2'></i>IFSC
                                        code
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control'
                                          id='account_no'
                                          placeholder='Enter IFSC code'
                                          name='ifsc'
                                          value={ifsc_code}
                                          onChange={e =>
                                            setIfsc_code(e.target.value)
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              {Boolean(isRazorPayPayoutActive) &&
                                type == 'upi' && (
                                  <div>
                                    <div>
                                      <label
                                        htmlFor='username '
                                        className='mr-5 commaon_label'
                                      >
                                        <i className='far fa-bank mr-2'></i>
                                        Account holder name
                                      </label>
                                      <div className='col-12 mb-3 p-0'>
                                        <input
                                          type='text'
                                          className='form-control commaon_input_box'
                                          id='account_no'
                                          placeholder='Enter Account Name'
                                          name='ifsc'
                                          value={holder_name}
                                          onChange={e =>
                                            setHolder_name(e.target.value)
                                          }
                                          required
                                        />
                                      </div>
                                    </div>

                                    <label
                                      htmlFor='username '
                                      className='mr-5 commaon_label'
                                    >
                                      <i className='far fa-bank mr-2'></i>UPI ID
                                    </label>
                                    <div className='col-12 mb-3 p-0'>
                                      <input
                                        type='text'
                                        className='form-control commaon_input_box'
                                        id='account_no'
                                        placeholder='Enter Your UPI ID Ex.(9999999999@xyz)'
                                        name='ifsc'
                                        value={upi_id}
                                        onChange={e =>
                                          setUpi_id(e.target.value)
                                        }
                                      />
                                    </div>

                                    <label
                                      htmlFor='username '
                                      className='mr-5 commaon_label'
                                    >
                                      <i className='far fa-bank mr-2'></i>{' '}
                                      Confirm UPI ID:
                                    </label>
                                    <div className='col-12 mb-3 p-0'>
                                      <input
                                        type='text'
                                        className='form-control commaon_input_box'
                                        id='account_no'
                                        placeholder='Re Enter Your UPI ID Ex.(9999999999@xyz)'
                                        name='ifsc'
                                        value={confirm_upi_id}
                                        onChange={e =>
                                          setConfirm_upi_id(e.target.value)
                                        }
                                      />
                                    </div>
                                    <small className='text-danger small_para'>
                                      कृपया सही UPI आईडी Ex.(9999999999@xyz)
                                      दर्ज करें।*
                                    </small>
                                  </div>
                                )}
                              {/* <button type="submit" className="btn w-50 d-block m-auto text-white btn-primary">Submit</button> */}
                            </div>
                          </div>

                          {/* bank details end */}

                          <div>
                            <label
                              htmlFor='username '
                              className='mr-5 commaon_label'
                            >
                              <i className='far fa-money mr-2'></i>Amount
                            </label>
                          </div>
                          <div className='field col-12 p-0 mt-1 mb-3'>
                            <input
                              type='phone'
                              className='form-control  search-slt commaon_input_box'
                              name='amount'
                              placeholder='amount'
                              // onChange={(e) => setAmount(e.target.value)}
                              onChange={e => {
                                const value = e.target.value
                                e.target.value = value.replace(/[^\d]/g, '')
                                setAmount(e.target.value)
                              }}
                            />
                          </div>
                          <div
                            className='col-12 p-0 mt-2 pt-3'
                            style={{ marginBottom: '100px' }}
                          >
                            {/* <button
                              type="button"
                              className=" btn-block btn-sm"
                              id="withdrawcase"
                              style={{
                                height: "40px",
                                backgroundColor: "#e15252",
                                color: "white",
                                borderRadius: "21px",
                              }}
                              disabled={Boolean(submitBtn) ? false : true}
                              onClick={() => updateBankDetails()}
                            >
                              Withdraw
                            </button> */}
                            {withdrawal ? (
                              <button
                                type='button'
                                className=' btn-block btn-sm '
                                id='withdrawcase'
                                style={{
                                  height: '40px',
                                  backgroundColor: '#e15252',
                                  color: 'white',
                                  borderRadius: '21px'
                                }}
                                disabled={Boolean(submitBtn) ? false : true}
                                onClick={() => updateBankDetails()}
                              >
                                Withdraw
                              </button>
                            ) : (
                              <p
                                id='withdrawClose'
                                style={{
                                  color: 'red',
                                  fontSize: '18px'
                                }}
                              >
                                Withdrawals services Temporary down
                              </p>
                            )}

                            {Boolean(submitBtn) ? '' : 'Reload Page'}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}

                {user && user.verified === 'unverified' && (
                  <div style={{ height: '100px' }} className='kyc_page_cards'>
                    <picture className='ml-3'>
                      <img
                        src='/images/alert.svg'
                        alt=''
                        width='32px'
                        className=''
                      />
                    </picture>
                    <p className='ml-1  mytext '>
                      <Link to="/profile">
                        Complete KYC to take Withdrawals
                      </Link>
                    </p>
                  </div>
                )}
                {user && user.verified === 'pending' && (
                  <div style={{ height: '100px' }} className='kyc_page_cards'>
                    <picture className='ml-3'>
                      <img
                        src='/images/alert.svg'
                        alt=''
                        width='32px'
                        className=''
                      />
                    </picture>
                    <p className='ml-1  mytext  '>
                      Please wait your kyc under process
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default Withdrawopt